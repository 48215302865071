import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private defaultTitle = 'spotNcharge - Borne simulateur';
  private defaultDescription = "L'app dédiée à la recharge des voitures électriques : Trouve les bornes publiques et partagées, calcule les temps de recharge, compare les tarifs et optimise les itinéraires.";

  constructor(private title: Title, private meta: Meta) {}

  // Cette méthode applique le SEO par défaut uniquement si aucun titre ou description spécifique n'est fourni
  applySeo({ title, description }: { title?: string; description?: string }) {
    const finalTitle = title || this.defaultTitle;
    const finalDescription = description || this.defaultDescription;

    // Appliquer seulement si le titre ou description spécifique n'est pas défini
    this.updateAllTitle(finalTitle);
    this.updateAllDescription(finalDescription);
  }

  updateAllTitle(title: string) {
    this.updateTitle(title);
    this.updateDcTitle(title);
  }

  updateAllDescription(description: string) {
    this.updateDescription(description);
    this.updateDcDescription(description);
    this.updateOgDescription(description);
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }

  updateDcTitle(text: string) {
    this.meta.updateTag({ name: 'dc.title', content: text });
  }

  updateDcDescription(text: string) {
    this.meta.updateTag({ name: 'dc.description', content: text });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateOgDescription(text: string) {
    this.meta.updateTag({ name: 'og:description', content: text });
  }
}

import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { AlertController, NavController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';

import { UserService } from '../services/user.service';
import { SideMenuService } from '../services/side-menu.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {
  form: FormGroup;
  sending = false;

  showPassword = false;
  passwordInputType = 'password';

  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private router: Router,
    private userService: UserService,
    private sideMenuService: SideMenuService,
    private navController: NavController,
    private menuCtrl: MenuController
  ) {}

  ngOnInit() {
    const emailFilledIn: string = window.localStorage.getItem('emailFilledIn');

    this.form = this.formBuilder.group({
      email: [emailFilledIn ? emailFilledIn : '', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.passwordInputType = this.showPassword ? 'text' : 'password';
  }

  async submitForm(): Promise<void> {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      // We save
      const data = Object.assign({}, this.form.value);

      this.sending = true;

      this.form.disable();

      try {
        await this.userService.login(this.form.value.email, this.form.value.password);

        this.sending = false;
        this.form.enable();
        this.form.reset();

        this.menuCtrl.close();
        this.sideMenuService.goToRoot();
        
        this.navController.navigateRoot(['/spot']);
      } catch (err) {
        //console.log(err);

        alert(err.message);

        this.sending = false;
        this.form.enable();
      }
    }
  }

  goBack(): void {
    this.sideMenuService.navigateBack();
  }

  goForward(page: string): void {
    this.sideMenuService.navigateForward(page);
  }
}

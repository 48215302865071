import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  pageToLoadInSideMenu: string;
  pageToLoadInSideMenuObservable: Observable<string>;

  private pageToLoadInSideMenuBehavior: BehaviorSubject<string>;

  constructor(private menuCtrl: MenuController) {
    this.pageToLoadInSideMenuBehavior = new BehaviorSubject<string>(null);
    this.pageToLoadInSideMenuObservable = this.pageToLoadInSideMenuBehavior.asObservable();
    this.pageToLoadInSideMenuObservable.subscribe(
      (pageToLoadInSideMenu: string) => (this.pageToLoadInSideMenu = pageToLoadInSideMenu)
    );
  }

  setRedirectToLoginPagesLogged(pageToLoadInSideMenu: string): void {
    if (this.pageToLoadInSideMenu !== pageToLoadInSideMenu) {
      this.pageToLoadInSideMenuBehavior.next(pageToLoadInSideMenu);
    } else {
      this.openMenu();
    }
  }

  navigateForward(pageToLoadInSideMenu: string): void {
    this.setRedirectToLoginPagesLogged(pageToLoadInSideMenu);
  }

  navigateBack(): void {
    this.setRedirectToLoginPagesLogged('back');
  }

  goToRoot(): void {
    this.setRedirectToLoginPagesLogged('root');
  }

  openMenu(): void {
    this.menuCtrl.open();
  }

  closeMenu(): void {
    this.menuCtrl.close();
  }
}

import { Injectable } from '@angular/core';

import { GraphqlService } from './graphql.service';
import { User } from '../models/user.interface';
import { UserService } from './user.service';
import { Review } from '../models/review.interface';
import { GraphQLInputObjectType, GraphQLInt, GraphQLString } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  collectionNamePlural = 'reviews';
  collectionNameSingular = 'review';

  filtersType = new GraphQLInputObjectType({
    name: 'filterType',
    fields: {
      itemsPerPage: { type: GraphQLInt },
      orderBy: { type: GraphQLString },
      direction: { type: GraphQLString },
      page: { type: GraphQLInt }
    }
  });

  graphqlQueries: {
    getAllItems: string;
    getChargingStationReviews: string;
    getChargingStationMessages: string;
    getItem: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
    addReview: string;
    countUnreadReviews: string;
  } = {
    getAllItems: `
		  query {
		    reviews {
				id
				usersId
				updatedAt

				reviewMessages {
					id
					message
					userId
					createdAt
				}

				users {
					id
					username
					profilePictureUrl
				}
		    }
		  }
		`,
    getChargingStationReviews: `
		  query getChargingStationReviews(
        $chargingStationId: String!, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String
        ) {
            reviews (
              chargingStationId: $chargingStationId,
              page: $page,
              itemsPerPage: $itemsPerPage,
              orderBy: $orderBy,
              direction: $direction,
            ) {,
              id,
              message,
              rating,
              createdAt,
              user {
                id
                username
                profilePictureUrl
              }
            }
          }
		`,
    getChargingStationMessages: `
		  query getChargingStationMessages(
        $chargingStationId: String!, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String
        ) {
            reviews (
              chargingStationId: $chargingStationId,
              page: $page,
              itemsPerPage: $itemsPerPage,
              orderBy: $orderBy,
              direction: $direction,
            ) {,
              id,
              message,
              createdAt,
              user {
                id
                username
                profilePictureUrl
              }
            }
          }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    review(id: $id) {
				id
				usersId
				updatedAt

				reviewMessages {
					id
					message
					userId
					createdAt
				}

				users {
					id
					username
					profilePictureUrl
				}
		    }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteReview(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem($id: ID!, $title: String!, $version: String!, $reviewBrandId: String!) {
				updateReview(id: $id, title: $title, version: $version, reviewBrandId: $reviewBrandId) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem($title: String!, $version: String!, $reviewBrandId: String!) {
				addReview(title: $title, version: $version, reviewBrandId: $reviewBrandId) {
					id
				}
			}
		`,
    addReview: `
			mutation addReview($userId: String!, $chargingStationId: String!, $message: String, $rating: Float) {
				addReview(message: $message, rating: $rating, chargingStationId: $chargingStationId, userId: $userId) {
					id
					message,
          rating,
					userId
					chargingStationId
					createdAt
				}
			}
		`,
    countUnreadReviews: `
		  query countUnreadReviews($userId: String!) {
		    countUnreadReviews(userId: $userId)
		  }
		`
  };

  currentUser: User;

  constructor(private graphqlService: GraphqlService, private userService: UserService) {
    this.userService.currentUserObservable.subscribe(
      (currentUser: User) => (this.currentUser = currentUser)
    );
  }

  async add(data: Review): Promise<Review> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.addItem, data);

      if (result.addReview) {
        return result.addReview as Review;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<Review> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.graphqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        const review: Review = result[this.collectionNameSingular] as Review;

        return review;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: Review): Promise<Review> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Review;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(): Promise<Review[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getAllItems
      );

      const items: Review[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Review);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addReviewFromCurrentUser(
    chargingStationId: string,
    message: string,
    rating: number
  ): Promise<Review> {
    if (this.currentUser) {
      return await this.addReviewByUser(this.currentUser.id, chargingStationId, message, rating);
    } else {
      return null;
    }
  }

  async addReviewByUser(
    userId: string,
    chargingStationId: string,
    message: string,
    rating: number
  ): Promise<Review> {
    try {
      //console.log(userId, chargingStationId, message, rating);
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.addReview, {
        userId,
        chargingStationId,
        message,
        rating
      });

      if (result.addReview) {
        return result.addReview as Review;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addMessageAndCreateReviewFromCurrentUser(
    otherUserId: string,
    message: string
  ): Promise<Review> {
    if (this.currentUser) {
      return await this.addMessageAndCreateReviewByUsers(this.currentUser.id, otherUserId, message);
    } else {
      return null;
    }
  }

  async addMessageAndCreateReviewByUsers(
    userSenderId: string,
    userReceivingId: string,
    message: string
  ): Promise<Review> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.addReview, {
        userId: userSenderId,
        userReceivingId,
        message
      });

      if (result.addReview) {
        return result.addReview as Review;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getChargingStationReviews(chargingStationId: string, filters: any): Promise<Review[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getChargingStationReviews,
        {
          chargingStationId,
          page: filters.page,
          itemsPerPage: filters.itemsPerPage,
          orderBy: filters.orderBy,
          direction: filters.direction
        }
      );

      const items: Review[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Review);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getChargingStationMessages(chargingStationId: string, filters: any): Promise<Review[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getChargingStationMessages,
        {
          chargingStationId,
          page: filters.page,
          itemsPerPage: filters.itemsPerPage,
          orderBy: filters.orderBy,
          direction: filters.direction
        }
      );

      const items: Review[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Review);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getUnreadReviewForCurrentUser(): Promise<number> {
    if (this.currentUser) {
      return await this.getUnreadReviewForUser(this.currentUser.id);
    } else {
      return null;
    }
  }

  async getUnreadReviewForUser(userId: string): Promise<number> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.countUnreadReviews,
        {
          userId
        }
      );

      if (result.countUnreadReviews) {
        return parseInt(result.countUnreadReviews, 10);
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}

import { Injectable, NgZone } from '@angular/core';
import { CanActivate } from '@angular/router';

import { NavController } from '@ionic/angular';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardNotLoggedAppService implements CanActivate {
  constructor(
    private navCtrl: NavController,
    private userService: UserService,
    private zone: NgZone
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const subscription = this.userService.isLoggedObservable.subscribe((isLogged: boolean) => {
        if (isLogged !== null && isLogged) {
          // Utilisateur n'est pas connecté ou n'a pas les droits administratifs nécessaires
          // Optionnel : Ajoutez une redirection si nécessaire
          // this.router.navigate(['/login']);

          resolve(false); // Refuser l'accès
        } else {
          resolve(true); // Permettre l'accès
        }

        // Important : Désabonner immédiatement pour éviter les fuites de mémoire
        subscription.unsubscribe();
      });
    });
  }
}

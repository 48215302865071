import { SafeHtml } from '@angular/platform-browser';

export enum AdContainer {
  topSideMenu = 'top-sidemenu',
  bottomSideMenu = 'bottom-sidemenu',
  marketplaceList = 'marketplace-list',
  marketplaceProductContent = 'marketplace-product-content',
  marketplaceProductSidebar = 'marketplace-product-sidebar',
  echatComments = 'echat-comments',
  echatStreamList = 'echat-stream-list',
  newsSimilarArticles = 'news-similar-articles',
  spotStationDescription = 'spot-station-description',
  myCarList = 'my-car-list',
  myCarItineraries = 'my-car-itineraries'
}

export const getAdContainerLabel = (container: AdContainer): string => {
  switch (container) {
    case AdContainer.topSideMenu:
      return 'Haut du menu latéral';
    case AdContainer.bottomSideMenu:
      return 'Bas du menu latéral';
    case AdContainer.marketplaceList:
      return 'Liste du marketplace';
    case AdContainer.marketplaceProductContent:
      return 'Contenu d\'un produit du marketplace';
    case AdContainer.marketplaceProductSidebar:
      return 'Sidebar d\'un produit du marketplace';
    case AdContainer.echatComments:
      return 'Commentaires de l\'eChat';
    case AdContainer.echatStreamList:
      return 'Liste des messages d\'eChat';
    case AdContainer.newsSimilarArticles:
      return 'Articles similaires d\'une news';
    case AdContainer.spotStationDescription:
      return 'Description d\'une station';
    case AdContainer.myCarList:
      return 'Liste de mes véhicules';
    case AdContainer.myCarItineraries:
      return 'Mes trajets d\'un véhicule';
    default:
      return '';
  }
};

export interface Ad {
  id: string;
  title: string;
  content: string;
  container: AdContainer;
  createdAt: Date;
  updatedAt: Date;
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-suggest-update',
  templateUrl: './suggest-update.component.html',
  styleUrls: ['./suggest-update.component.scss']
})
export class SuggestUpdateComponent implements OnInit {
  updateTypes = [
    { type: 'localisation', label: 'Modifier la localisation de la station' },
    { type: 'tarification', label: 'Signaler une erreur de tarification' },
    { type: 'connecteurs', label: 'Modifier les connecteurs' },
    { type: 'fermeture', label: 'Station fermée définitivement' },
    { type: 'autre', label: 'Autre suggestion' }
  ];
  selectedUpdateType;

  plugs = [
    {
      label: 'Domestique UE',
      icon: 'plug2',
      selected: false
    },
    {
      label: 'Type 2',
      icon: 'plug2',
      selected: true
    },
    {
      label: 'Type 3C',
      icon: 'plug2',
      selected: false
    },
    {
      label: 'Type 1',
      icon: 'plug2',
      selected: false
    },
    {
      label: 'Type2',
      icon: 'plug2',
      selected: false
    },
    {
      label: 'CHAdeMO',
      icon: 'plug2',
      selected: false
    }
  ];

  quantity = 1;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  notImplemented() {
    alert('In developement');
  }

  submit() {
    this.close();
  }

  close() {
    this.modalController.dismiss();
  }

  goBack(): void {
    this.selectedUpdateType = null;
  }
}

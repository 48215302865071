import { Chat } from './chat.interface';
import { User } from './user.interface';

export interface ChatMessage {
  id: string;
  type: ChatMessageType;
  message: string;
  chatId: string;
  chat: Chat;
  userId: string;
  user: User;
  readBy: string[];
  createdAt: Date;
  updatedAt: Date;
}

export enum ChatMessageType {
  message = 'message',
  image = 'image'
}

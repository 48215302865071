import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Notification } from '../models/notification.interface';

import { GraphqlService } from './graphql.service';
import { User } from '../models/user.interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  collectionNamePlural = 'notifications';
  collectionNameSingular = 'notification';

  grahqlQueries: {
    getAllItems: string;
    getUserNotifications: string;
    getItem: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
    countUnreadNotifications: string;
    updateField: string;
  } = {
    getAllItems: `
		  query {
		    notifications {
          id
          title
          type
          userId
          fromUserId
          fromUserUsername
          read
          collectionName
          docId
		  createdAt
          
      fromUser {
            id
            username
			profilePictureUrl
          }
		    }
		  }
		`,
    getUserNotifications: `
		  query getUserNotifications($userId: String!, $orderBy: String, $direction: String, $page: Int, $itemsPerPage: Int) {
		    notifications(userId: $userId, orderBy: $orderBy, direction: $direction,
          page: $page,
          itemsPerPage: $itemsPerPage
        ) {
          id
          title
          type
          userId
          fromUserId
          fromUserUsername
          read
          collectionName
          docId
		  createdAt
          
      fromUser {
            id
            username
			profilePictureUrl
          }
        }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    notification(id: $id) {
          id
          title
          type
          userId
          fromUserId
          fromUserUsername
          read
          collectionName
          docId
		  createdAt
          
      fromUser {
            id
            username
			profilePictureUrl
          }
		    }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteNotification(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem($id: ID!, $title: String!, $type: String!, 
        $userId: String!, $read: Boolean!, $collectionName: String!, $docId: String!) {
				updateNotification(id: $id, title: $title, type: $type, 
          userId: $userId, read: $read, collectionName: $collectionName, docId: $docId) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem($title: String!, $type: String!, $userId: String!, 
        $read: Boolean!, $collectionName: String!, $docId: String!) {
				addNotification(title: $title, type: $type, userId: $userId, 
          read: $read, collectionName: $collectionName, docId: $docId) {
					id
				}
			}
		`,
    countUnreadNotifications: `
		  query countUnreadNotifications($userId: String!) {
		    countUnreadNotifications(userId: $userId)
		  }
		`,
    updateField: `
			mutation updateField($id: ID!, $field: String!, $value: String!) {
				updateField(id: $id, field: $field, value: $value) {
					id
				}
			}
		`
  };

  currentUserNotifications: Notification[] = [];
  currentUserNotificationsObservable: Observable<Notification[]>;

  countCurrentUserNotificationsUnread = 0;
  countCurrentUserNotificationsUnreadObservable: Observable<number>;

  currentUser: User;

  private currentUserNotificationsBehavior: BehaviorSubject<Notification[]>;
  private countCurrentUserNotificationsUnreadBehavior: BehaviorSubject<number>;

  constructor(private graphqlService: GraphqlService, private userService: UserService) {
    this.currentUserNotificationsBehavior = new BehaviorSubject<Notification[]>([]);
    this.currentUserNotificationsObservable = this.currentUserNotificationsBehavior.asObservable();
    this.currentUserNotificationsObservable.subscribe(
      (currentUserNotifications: Notification[]) =>
        (this.currentUserNotifications = currentUserNotifications)
    );

    this.countCurrentUserNotificationsUnreadBehavior = new BehaviorSubject<number>(0);
    this.countCurrentUserNotificationsUnreadObservable =
      this.countCurrentUserNotificationsUnreadBehavior.asObservable();
    this.countCurrentUserNotificationsUnreadObservable.subscribe(
      (countCurrentUserNotificationsUnread: number) =>
        (this.countCurrentUserNotificationsUnread = countCurrentUserNotificationsUnread)
    );

    this.userService.currentUserObservable.subscribe(
      (currentUser: User) => (this.currentUser = currentUser)
    );
  }

  setCurrentUserNotifications(currentUserNotifications: Notification[]): void {
    let countUnread = 0;
    for (const currentUserNotification of currentUserNotifications) {
      if (!currentUserNotification.read) {
        countUnread++;
      }
    }

    this.currentUserNotificationsBehavior.next(currentUserNotifications);

    this.setCountCurrentUserNotificationsUnread(countUnread);
  }

  setCountCurrentUserNotificationsUnread(countCurrentUserNotificationsUnread: number): void {
    this.countCurrentUserNotificationsUnreadBehavior.next(countCurrentUserNotificationsUnread);
  }

  async add(data: Notification): Promise<Notification> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addNotification) {
        return result.addNotification as Notification;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<Notification> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Notification;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: Notification): Promise<Notification> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Notification;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(): Promise<Notification[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems
      );

      const items: Notification[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Notification);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getUserNotifications(
    userId: string,
    itemsPerPage: number = 100,
    page: number = 0
  ): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getUserNotifications,
        {
          userId,
          orderBy: 'createdAt',
          direction: 'desc',
          itemsPerPage,
          page
        }
      );

      const items: Notification[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Notification);
        }
      }

      this.setCurrentUserNotifications(items);
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getUnreadNotificationForCurrentUser(): Promise<number> {
    if (this.currentUser) {
      const countUnread: number = await this.getUnreadNotificationForUser(this.currentUser.id);

      this.setCountCurrentUserNotificationsUnread(countUnread);

      return countUnread;
    } else {
      return null;
    }
  }

  async getUnreadNotificationForUser(userId: string): Promise<number> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.countUnreadNotifications,
        {
          userId
        }
      );

      if (result.countUnreadNotifications) {
        return parseInt(result.countUnreadNotifications, 10);
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateField(id: string, field: string, value: any): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.updateField, {
        id,
        field,
        value: JSON.stringify(value)
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}

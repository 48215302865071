import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastController, Platform, AlertController } from '@ionic/angular';
import { Geolocation, PermissionStatus } from '@capacitor/geolocation';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

import { Address } from 'src/app/models/address.interface';

declare const window: Window;

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  googleMapKey = 'AIzaSyA0PemDxzn0cSaXCo11AF3VCdcKz_MWhFg';

  toast: HTMLIonToastElement;

  coordinates: {
    latitude: number;
    longitude: number;
  };
  centerCoordinatesObservable: Observable<{
    latitude: number;
    longitude: number;
  }>;
  watchPositionId: string;

  toastVisible = false;
  isTracking = false;

  private centerCoordinatesBehavior: BehaviorSubject<{
    latitude: number;
    longitude: number;
  }>;

  constructor(
    // private geolocation: Geolocation,
    private toastController: ToastController,
    // private nativeGeocoder: NativeGeocoder,
    private platform: Platform,
    private alertController: AlertController
  ) {
    this.centerCoordinatesBehavior = new BehaviorSubject<{
      latitude: number;
      longitude: number;
    }>(this.coordinates);
    this.centerCoordinatesObservable = this.centerCoordinatesBehavior.asObservable();
    this.centerCoordinatesObservable.subscribe(
      (coordinates: { latitude: number; longitude: number }) => (this.coordinates = coordinates)
    );
  }

  setCenterCoordinates(coordinates: { latitude: number; longitude: number }): void {
    this.centerCoordinatesBehavior.next(coordinates);
  }

  async startGeoTracking(options?: PositionOptions): Promise<void> {
    // Utiliser des options personnalisées si elles sont fournies, sinon, utilisez les valeurs par défaut
    const geoOptions: PositionOptions = options || { enableHighAccuracy: true };
  
    //then Navigation APIs
    this.initGeolocation(geoOptions);
  }

  async managePermissions(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const permissionStatus: PermissionStatus = await Geolocation.requestPermissions();

        if (
          permissionStatus.location === 'denied' &&
          permissionStatus.coarseLocation === 'denied'
        ) {
          await this.alertToOpenSettings();

          reject();
        } else {
          resolve();
        }
      } catch (err) {
        //console.log(err);

        await this.alertToOpenSettings();

        resolve();
      }
    });
  }

  async alertToOpenSettings(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: 'Autorisation géolocalisation',
        message: 'Veuillez autoriser la géolocalisation pour spotNcharge depuis les Réglages',
        buttons: [
          {
            text: 'Non',
            role: 'cancel'
          },
          {
            text: 'Ouvrir les réglages',
            handler: () => {
              NativeSettings.open({
                optionAndroid: AndroidSettings.ApplicationDetails,
                optionIOS: IOSSettings.App
              });
            }
          }
        ]
      });

      await alert.present();

      await alert.onDidDismiss();

      resolve();
    });
  }

  initGeolocation(geoOptions: PositionOptions): void {
    this.isTracking = true;
  
    if (this.platform.is('capacitor')) {
      this.managePermissions()
        .then(() => {
          this.startWatchPosition(geoOptions);
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      this.startWatchPosition(geoOptions);
    }
  }

async startWatchPosition(geoOptions: PositionOptions): Promise<void> {
  try {
    this.watchPositionId = await Geolocation.watchPosition(
      geoOptions,
      (position, err) => {
        if (position && position.coords) {
          this.setCenterCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        }
      }
    );
  } catch (err) {
    //console.error('Erreur lors du démarrage de la géolocalisation:', err);
  }
  }

  stopGeoTracking(): void {
    if (this.watchPositionId) {
      Geolocation.clearWatch({ id: this.watchPositionId });
    }
  }

  getCurrentAddress(): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition(
          currentPosition => {
            this.getAddressFromCoordinates(currentPosition.coords).then(address => {
              resolve(address);
            });
          },
          err => {
            console.error(err);
            reject(err);
          },
          { enableHighAccuracy: true }
        );
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  getCurrentAddressAndCoordinates(): Promise<{
    address: string;
    coordinates: { latitude: number; longitude: number };
  }> {
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition(
          currentPosition => {
            this.getAddressFromCoordinates(currentPosition.coords).then(address => {
              resolve({
                address,
                coordinates: currentPosition.coords
              });
            });
          },
          err => {
            console.error(err);
            reject(err);
          },
          { enableHighAccuracy: true }
        );
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  getAddressFromCoordinates(coordinates: { latitude: number; longitude: number }): Promise<string> {
    return new Promise((resolve, reject) => {
      const geocodor = new google.maps.Geocoder();
      const latLng = new google.maps.LatLng(coordinates.latitude, coordinates.longitude);

      geocodor.geocode(
        {
          location: latLng
        },
        (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
          if (status === 'OK') {
            resolve(results[0].formatted_address.replace(', France', ''));
          } else {
            reject(status);
          }
        }
      );
      //   }
    });
  }

  getCoordinatesFromAddress(address: string): Promise<{
    latitude: number;
    longitude: number;
  }> {
    return new Promise((resolve, reject) => {
      const geocodor = new google.maps.Geocoder();

      geocodor.geocode(
        {
          address,
          componentRestrictions: {
            country: 'fr'
          }
        },
        (results: Array<any>, status: string) => {
          if (status === 'OK') {
            resolve({
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            });
          } else {
            reject(status);
          }
        }
      );
    });
  }

  autocompleteAdress(
    query: string,
    country: string = 'fr',
    citiesOnly: boolean = false
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    return new Promise((resolve, reject) => {
      const autocompleteService = new google.maps.places.AutocompleteService();

      autocompleteService.getPlacePredictions(
        {
          input: query,
          componentRestrictions: {
            country
          },
          types: citiesOnly ? ['(cities)'] : null
        },
        (
          results: google.maps.places.AutocompletePrediction[],
          status: google.maps.places.PlacesServiceStatus
        ) => {
          if (status === 'OK') {
            resolve(results);
          } else if (status === 'ZERO_RESULTS') {
            resolve([]);
          } else {
            reject(status);
          }
        }
      );
    });
  }

  getPlaceDetails(placeId: string): Promise<Address> {
    return new Promise((resolve, reject) => {
      const placeService = new google.maps.places.PlacesService(
        document.getElementById('google-map-geolocation') as HTMLDivElement
      );

      placeService.getDetails(
        {
          placeId
        },
        (place: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
          if (status === 'OK') {
            const address: Address = {
              placeId,
              formattedAddress: place.formatted_address.replace(', France', ''),
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
              streetNumber: {
                longName: null,
                shortName: null
              },
              route: {
                longName: null,
                shortName: null
              },
              city: {
                longName: null,
                shortName: null
              },
              department: {
                longName: null,
                shortName: null
              },
              region: {
                longName: null,
                shortName: null
              },
              country: {
                longName: null,
                shortName: null
              },
              postalCode: {
                longName: null,
                shortName: null
              }
            };

            for (const addressComponent of place.address_components) {
              switch (addressComponent.types[0]) {
                case 'street_number':
                  address.streetNumber = {
                    longName: addressComponent.long_name,
                    shortName: addressComponent.short_name
                  };
                  break;
                case 'route':
                  address.route = {
                    longName: addressComponent.long_name,
                    shortName: addressComponent.short_name
                  };
                  break;
                case 'street_number':
                  address.streetNumber = {
                    longName: addressComponent.long_name,
                    shortName: addressComponent.short_name
                  };
                  break;
                case 'locality':
                  address.city = {
                    longName: addressComponent.long_name,
                    shortName: addressComponent.short_name
                  };
                  break;
                case 'postal_town':
                  if (typeof address.city === 'undefined') {
                    address.city = {
                      longName: addressComponent.long_name,
                      shortName: addressComponent.short_name
                    };
                  }
                  break;
                case 'administrative_area_level_2':
                  address.department = {
                    longName: addressComponent.long_name,
                    shortName: addressComponent.short_name
                  };
                  break;
                case 'administrative_area_level_1':
                  address.region = {
                    longName: addressComponent.long_name,
                    shortName: addressComponent.short_name
                  };
                  break;
                case 'country':
                  address.country = {
                    longName: addressComponent.long_name,
                    shortName: addressComponent.short_name
                  };
                  break;
                case 'postal_code':
                  address.postalCode = {
                    longName: addressComponent.long_name,
                    shortName: addressComponent.short_name
                  };
                  break;
              }
            }

            resolve(address);
          } else {
            reject(status);
          }
        }
      );
    });
  }

  getDistanceBetweenPoints(lat1: number, lng1: number, lat2: number, lng2: number): number {
    // The radius of the planet earth in meters
    const R = 6371; // Radius of the earth in km
    const dLat = this.degreesToRadians(lat2 - lat1); // deg2rad below
    const dLon = this.degreesToRadians(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.degreesToRadians(lat1)) *
        Math.cos(this.degreesToRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km

    return d;
  }

  openDirectionInExternalMap(latitude: number, longitude: number): void {
    const url: string =
      'https://www.google.com/maps/dir/?api=1&destination=' +
      latitude +
      ',' +
      longitude +
      '&travelmode=driving';

    window.open(url, '_blank');
  }

  getGoogleMapPlacePhotoUrl(photoReference: string): string {
    return (
      'https://maps.googleapis.com/maps/api/place/photo?photo_reference=' +
      photoReference +
      '&key=' +
      this.googleMapKey +
      '&maxwidth=1200'
    );
  }

  private degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import {
  CurrenciesList,
  DEFAULT_CURRENCY,
  DEFAULT_TVA_RATE,
  Quotation,
  TvaList
} from 'src/app/models/quotation.interface';
import { User } from 'src/app/models/user.interface';
import { QuotationService } from 'src/app/services/quotation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-quotation-edit',
  templateUrl: './quotation-edit.page.html',
  styleUrls: ['./quotation-edit.page.scss']
})
export class QuotationEditPage implements OnInit, OnChanges {
  @Input() userGravityFormEntryId: string;
  @Input() itemId: string;
  @Input() quotation: Quotation;

  form: FormGroup;

  data: any;

  TvaList = TvaList;
  CurrenciesList = CurrenciesList;

  currentUser: User;

  confirmSending = false;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private quotationService: QuotationService,
    private userService: UserService,
    private alertController: AlertController
  ) {
    this.userService.currentUserObservable.subscribe((currentUser: User) => {
      this.currentUser = currentUser;
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      reference: ['', [Validators.required]],
      currency: [DEFAULT_CURRENCY, [Validators.required]],
      products: new FormArray([], [Validators.required]),
      amountHtTotal: new FormControl('', [Validators.required]),
      amountTvaTotal: new FormControl(''),
      amountTtcTotal: new FormControl('', [Validators.required])
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.itemId = this.activatedRoute.snapshot.paramMap.get('id');

      if (this.quotation) {
        this.itemId = this.quotation.id;
      }

      if (this.itemId || this.quotation) {
        this.setValues();
      } else {
        this.form.enable();
      }
    });
  }

  ngOnChanges() {
    if (this.quotation) {
      this.itemId = this.quotation.id;
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  addProductFormGroup(): void {
    const productsFormArray = this.form.get('products') as FormArray;

    productsFormArray.push(
      new FormGroup({
        id: new FormControl(this.generateRandomId(), [Validators.required]),
        description: new FormControl('', [Validators.required]),
        qty: new FormControl(1, [Validators.required]),
        amountHtPerUnit: new FormControl('', [Validators.required]),
        amountHt: new FormControl('', [Validators.required]),
        tvaRate: new FormControl(DEFAULT_TVA_RATE, [Validators.required]),
        amountTva: new FormControl('', [Validators.required]),
        amountTtc: new FormControl('', [Validators.required]),
        currency: new FormControl(this.form.value.currency, [Validators.required])
      })
    );

    this.form.updateValueAndValidity();
  }

  removeProduct(i: number): void {
    const productsFormArray: FormArray = this.form.get('products') as FormArray;

    productsFormArray.removeAt(i);

    this.updateTotalAmount();
  }

  updateProductAmount(i: number): void {
    const productsFormArray: FormArray = this.form.get('products') as FormArray;

    productsFormArray
      .at(i)
      .get('amountHt')
      .setValue(productsFormArray.value[i].amountHtPerUnit * productsFormArray.value[i].qty);

    productsFormArray.at(i).get('amountHt').updateValueAndValidity();

    this.udpatedProductsAmounts();
  }

  udpatedProductsAmounts(): void {
    const productsFormArray: FormArray = this.form.get('products') as FormArray;

    for (let i = 0; i < this.form.value.products.length; i++) {
      const amountTva =
        parseFloat(this.form.value.products[i].tvaRate) === 0
          ? 0
          : Math.round(
              (this.form.value.products[i].amountHt / 100) *
                parseFloat(this.form.value.products[i].tvaRate) *
                100
            ) / 100;

      productsFormArray.at(i).get('amountTva').setValue(amountTva);

      productsFormArray
        .at(i)
        .get('amountTtc')
        .setValue(this.form.value.products[i].amountHt + amountTva);
    }

    this.updateTotalAmount();
  }

  updateTotalAmount(): void {
    for (const field of ['amountHt', 'amountTva', 'amountTtc']) {
      this.form.get(field + 'Total').setValue(0);
    }

    for (const product of this.form.value.products) {
      for (const field of ['amountHt', 'amountTva', 'amountTtc']) {
        this.form.get(field + 'Total').setValue(this.form.value[field + 'Total'] + product[field]);
      }
    }
  }

  async setValues(): Promise<void> {
    this.data = this.quotation
      ? this.quotation
      : await this.quotationService.getFromId(this.itemId);

    if (this.form && this.data) {
      for (const field of [
        'reference',
        'currency',
        'amountHtTotal',
        'amountTvaTotal',
        'amountTtcTotal'
      ]) {
        this.form.get(field).setValue(this.data[field]);
      }

      const products: FormArray = this.form.get('products') as FormArray;
      for (let i = 0; i < this.data.products.length; i++) {
        this.addProductFormGroup();

        for (const field of [
          'id',
          'description',
          'qty',
          'amountHtPerUnit',
          'amountHt',
          'tvaRate',
          'amountTva',
          'amountTtc',
          'currency'
        ]) {
          products.at(i).get(field).setValue(this.data.products[i][field]);
        }
      }
      this.form.enable();
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const data = Object.assign(
        {
          userId: this.currentUser.id,
          userGravityFormEntryId: this.userGravityFormEntryId
        },
        this.form.value
      );

      this.form.disable();
      //console.log(this.itemId);
      if (this.itemId) {
        data.id = this.itemId;
        try {
          const result: Quotation = await this.quotationService.update(data);
          this.form.enable();
          const alertEl = await this.alertController.create({
            cssClass: 'custom-alert',
            header: 'Succès',
            message: 'Données sauvegardées avec succès.',
            buttons: [
              {
                text: 'Ok',
                cssClass: 'success',
                handler: () => {
                  this.closeModal();
                }
              }
            ]
          });
          await alertEl.present();
        } catch (err) {
          //console.log(err);
          this.form.enable();
          const alertEl = await this.alertController.create({
            cssClass: 'custom-alert',
            header: 'Erreur',
            message: err.message,
            buttons: [
              {
                text: 'Ok',
                cssClass: 'danger',
                handler: () => {
                  this.form.enable();
                }
              }
            ]
          });
          await alertEl.present();
        }
      } else {
        try {
          const result: Quotation = await this.quotationService.add(data);
          //console.log(result);
          this.itemId = result.id;
          this.form.enable();
          const alertEl = await this.alertController.create({
            cssClass: 'custom-alert',
            header: 'Succès',
            message: 'Données sauvegardées avec succès.',
            buttons: [
              {
                text: 'Ok',
                cssClass: 'success',
                handler: () => {
                  this.closeModal();
                }
              }
            ]
          });
          await alertEl.present();
        } catch (err) {
          //console.log(err);
          this.form.enable();
          const alertEl = await this.alertController.create({
            cssClass: 'custom-alert',
            header: 'Erreur',
            message: err.message,
            buttons: [
              {
                text: 'Ok',
                cssClass: 'danger',
                handler: () => {
                  this.form.enable();
                }
              }
            ]
          });
          await alertEl.present();
        }
      }
    }
  }

  formatPrice(value: number, currency: CurrenciesList = null): string {
    if (!currency && this.form.value.currency) {
      currency = this.form.value.currency;
    }

    if (currency) {
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2
      });

      return formatter.format(value);
    }

    return '0';
  }

  private generateRandomId(): string {
    return Math.floor(new Date().valueOf() * Math.random()).toString();
  }
}

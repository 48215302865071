/* eslint-disable prefer-arrow/prefer-arrow-functions */

import { SafeResourceUrl } from '@angular/platform-browser';

/* eslint-disable @typescript-eslint/naming-convention */
export enum PageType {
  WordpressPage = 'wordpressPage',
  GravityForm = 'gravityForm'
}

export function getPageTypeLabel(type: PageType): string {
  switch (type) {
    case PageType.WordpressPage:
      return 'Page';
    case PageType.GravityForm:
      return 'Formulaire';
  }

  return type;
}

export enum PageContainer {
  SideMenu = 'sidemenu',
  Welcome = 'accueil',
  Charge = 'charge',
  Settings = 'settings'
}

export function getPageContainerLabel(container: PageContainer): string {
  switch (container) {
    case PageContainer.SideMenu:
      return 'Menu latéral';
    case PageContainer.Welcome:
      return 'Accueil';
    case PageContainer.Charge:
      return 'Charge';
    case PageContainer.Settings:
      return 'Profil';
    default:
      return container;
  }
}

export enum PageVisibility {
  all = 'all',
  loggedInUsers = 'loggedInUsers',
  notLoggedInUsers = 'notLoggedInUsers',
  loggedInUsersNotPro = 'loggedInUsersNotPro',
  loggedInUsersPro = 'loggedInUsersPro',
  none = 'none'
}

export function getPageVisibilityLabel(pageVisibility: PageVisibility): string {
  switch (pageVisibility) {
    case PageVisibility.none:
      return 'Aucune';
    case PageVisibility.all:
      return 'Tout le monde';
    case PageVisibility.loggedInUsers:
      return 'Membres connectés uniquement';
    case PageVisibility.notLoggedInUsers:
      return 'Membres non-connectés uniquement';
    case PageVisibility.loggedInUsersNotPro:
      return 'Membres connectés non-partenaire uniquement';
    case PageVisibility.loggedInUsersPro:
      return 'Membres connectés partenaire uniquement';
    default:
      return pageVisibility;
  }
}

export interface Page {
  id: string;
  title: string;
  slug: string;
  subtitle: string;
  icon: string;
  description: string;
  container: PageContainer;
  type: PageType;
  wordpressPageUrl: string;
  gravityFormId: string;
  callToActionEnabled: boolean;
  callToActionTitle: string;
  callToActionType: PageType;
  callToActionWordpressPageUrl: string;
  callToActionGravityFormId: string;
  position: number;
  pageVisibility: PageVisibility;
  createdAt: Date;
  updatedAt: Date;
}

import { Injectable } from '@angular/core';

import { Chat } from '../models/chat.interface';
import { ChatMessage, ChatMessageType } from '../models/chat-message.interface';

import { GraphqlService } from './graphql.service';
import { User } from '../models/user.interface';
import { UserService } from './user.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  collectionNamePlural = 'chats';
  collectionNameSingular = 'chat';
  collectionNameChatByTwoUsers = 'chatByTwoUsers';

  grahqlQueries: {
    getAllItems: string;
    getUserChats: string;
    getItem: string;
    subscriptionChatMessages: string;
    subscriptionChats: string;
    deleteItem: string;
    archiveForUser: string;
    updateItem: string;
    addItem: string;
    addChatMessage: string;
    countUnreadChats: string;
    setChatMessageAsRead: string;
    getChatByTwoUsers: string;
    mutateUnArchiveChatForUser: string;
  } = {
    getAllItems: `
		  query {
		    chats {
				id
				usersId
				updatedAt
				lastMessagePreview
				readBy
        archivedForUsers

				chatMessages {
					id
          type
					message
					userId
					createdAt
				}

				users {
					id
					username
					profilePictureUrl
					lastLoginDate
          isLogged
				}
		    }
		  }
		`,
    getUserChats: `
		  query getUserChats($userId: String!, $orderBy: String, $direction: String) {
		    chats(userId: $userId, orderBy: $orderBy, direction: $direction) {
				id
				usersId
				updatedAt
				lastMessagePreview
				readBy
        archivedForUsers

				chatMessages {
					id
          type
					message
					userId
					createdAt
				}

				users {
					id
					username
					profilePictureUrl
					lastLoginDate
          isLogged
				}
		    }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    chat(id: $id) {
				id
				usersId
				updatedAt
				lastMessagePreview
        archivedForUsers

				chatMessages {
					id
          type
					message
					userId
					createdAt
				}

				users {
					id
					username
					profilePictureUrl
					lastLoginDate
          isLogged
				}
		    }
		  }
		`,
    subscriptionChatMessages: `
		  subscription updatedChatMessage($chatId: ID!, $currentUserId: ID!) {
		    updatedChatMessage(chatId: $chatId, currentUserId: $currentUserId) {
          id
          type
          message
					userId
					chatId
					createdAt
        }
		  }
		`,
    subscriptionChats: `
		  subscription updatedChat($currentUserId: ID!) {
		    updatedChat(currentUserId: $currentUserId) {
          id
          usersId
          updatedAt
          lastMessagePreview
          readBy
          archivedForUsers

				chatMessages {
					id
          type
					message
					userId
					createdAt
				}

				users {
					id
					username
					profilePictureUrl
					lastLoginDate
          isLogged
				}
        }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteChat(id: $id) {
					id
				}
			}
		`,
    archiveForUser: `
			mutation archiveForUser($id: ID!, $userId: String!) {
				archiveChatForUser(id: $id, userId: $userId) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem($id: ID!, $title: String!, $version: String!, $chatBrandId: String!) {
				updateChat(id: $id, title: $title, version: $version, chatBrandId: $chatBrandId) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem($title: String!, $version: String!, $chatBrandId: String!) {
				addChat(title: $title, version: $version, chatBrandId: $chatBrandId) {
					id
				}
			}
		`,
    addChatMessage: `
			mutation addChatMessage($type: String!, $message: String!, $chatId: String, $userId: String!, $userReceivingId: String) {
				addChatMessage(type: $type, message: $message, chatId: $chatId, userId: $userId, userReceivingId: $userReceivingId) {
					id
          type
					message
					userId
					chatId
					createdAt
				}
			}
		`,
    countUnreadChats: `
		  query countUnreadChats($userId: String!) {
		    countUnreadChats(userId: $userId)
		  }
		`,
    setChatMessageAsRead: `
      mutation setChatMessageAsRead($chatId: String!, $userId: String!) {
        setChatMessageAsRead(chatId: $chatId, userId: $userId) {
          id
          usersId
          updatedAt
          lastMessagePreview
          readBy
          archivedForUsers
          chatMessageArchived

          chatMessages {
            id
            type
            message
            userId
            createdAt
          }

          users {
            id
            username
            profilePictureUrl
            lastLoginDate
            isLogged
          }
          }
      }
    `,
    getChatByTwoUsers: `
    query queryMessagesForTwoUsers($idFirstUser: ID!, $idSecondUser: ID!) {
      chatByTwoUsers(idFirstUser: $idFirstUser, idSecondUser: $idSecondUser) {
        id,
        archivedForUsers
      }
    }
    `,
    mutateUnArchiveChatForUser: `
    mutation unArchiveChatForUser($id: ID!) {
      unArchiveChatForUser(id: $id) {
        id
      }
    }`
  };

  countCurrentUserChatsUnread = 0;
  countCurrentUserChatsUnreadObservable: Observable<number>;

  currentUser: User;

  private countCurrentUserChatsUnreadBehavior: BehaviorSubject<number>;

  constructor(private graphqlService: GraphqlService, private userService: UserService) {
    this.countCurrentUserChatsUnreadBehavior = new BehaviorSubject<number>(0);
    this.countCurrentUserChatsUnreadObservable =
      this.countCurrentUserChatsUnreadBehavior.asObservable();
    this.countCurrentUserChatsUnreadObservable.subscribe(
      (countCurrentUserChatsUnread: number) =>
        (this.countCurrentUserChatsUnread = countCurrentUserChatsUnread)
    );

    this.userService.currentUserObservable.subscribe(
      (currentUser: User) => (this.currentUser = currentUser)
    );
  }

  setCountCurrentUserChatsUnread(countCurrentUserChatsUnread: number): void {
    this.countCurrentUserChatsUnreadBehavior.next(countCurrentUserChatsUnread);
  }

  async add(data: Chat): Promise<Chat> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addChat) {
        return result.addChat as Chat;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<Chat> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        let chat: Chat = result[this.collectionNameSingular] as Chat;

        for (const user of chat.users) {
          if (this.currentUser && user.id !== this.currentUser.id) {
            chat = Object.assign({ otherUser: user }, chat);
            break;
          }
        }

        return chat;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getChatByTwoUsers(idFirstUser: string, idSecondUser: string): Promise<string> {

    //console.log('users depuis services ', idFirstUser, ' ',idSecondUser);

    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getChatByTwoUsers, {
        idFirstUser,
        idSecondUser
      });

      const data = result.chatByTwoUsers;
      //console.log('data ', data);


      if (data) {
        if(data.archivedForUsers.includes(idFirstUser) || data.archivedForUsers.includes(idSecondUser)) {
            this.unArchiveChatForUser(data.id);
        }
        return data.id;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  listenChat(chatId: string, currentUserId: string): Observable<any> {
    return this.graphqlService.apolloSubscription(this.grahqlQueries.subscriptionChatMessages, {
      chatId,
      currentUserId
    });
  }

  listenChats(currentUserId: string): Observable<any> {
    return this.graphqlService.apolloSubscription(this.grahqlQueries.subscriptionChats, {
      currentUserId
    });
  }

  async unArchiveChatForUser(id: string): Promise<void> {

    //console.log('id ', id);

    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.mutateUnArchiveChatForUser, {
        id
        });

        //console.log('result unArchived ', result);


      /*if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Chat;*/
      //}
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: Chat): Promise<Chat> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Chat;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async archiveForUser(id: string, userId: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.archiveForUser,
        {
          id,
          userId
        }
      );
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(): Promise<Chat[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems
      );

      const items: Chat[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Chat);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addMessageToChatFromCurrentUser(chatId: string, message: string): Promise<ChatMessage> {
    if (this.currentUser) {
      return await this.addMessageToChatByUser(this.currentUser.id, chatId, message);
    } else {
      return null;
    }
  }

  async addImageToChatFromCurrentUser(chatId: string, imageUrl: string): Promise<ChatMessage> {
    if (this.currentUser) {
      return await this.addImageToChatByUser(this.currentUser.id, chatId, imageUrl);
    } else {
      return null;
    }
  }

  async addMessageToChatByUser(
    userId: string,
    chatId: string,
    message: string
  ): Promise<ChatMessage> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.addChatMessage,
        {
          type: ChatMessageType.message,
          userId,
          chatId,
          message
        }
      );

      if (result.addChatMessage) {
        return result.addChatMessage as ChatMessage;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addImageToChatByUser(
    userId: string,
    chatId: string,
    imageUrl: string
  ): Promise<ChatMessage> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.addChatMessage,
        {
          type: ChatMessageType.image,
          userId,
          chatId,
          message: imageUrl
        }
      );

      if (result.addChatMessage) {
        return result.addChatMessage as ChatMessage;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addMessageAndCreateChatFromCurrentUser(
    otherUserId: string,
    message: string
  ): Promise<ChatMessage> {
    if (this.currentUser) {
      return await this.addMessageAndCreateChatByUsers(this.currentUser.id, otherUserId, message);
    } else {
      return null;
    }
  }

  async addImageAndCreateChatFromCurrentUser(
    otherUserId: string,
    imageUrl: string
  ): Promise<ChatMessage> {
    if (this.currentUser) {
      return await this.addImageAndCreateChatByUsers(this.currentUser.id, otherUserId, imageUrl);
    } else {
      return null;
    }
  }

  async addMessageAndCreateChatByUsers(
    userSenderId: string,
    userReceivingId: string,
    message: string
  ): Promise<ChatMessage> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.addChatMessage,
        {
          type: ChatMessageType.message,
          userId: userSenderId,
          userReceivingId,
          message
        }
      );

      if (result.addChatMessage) {
        return result.addChatMessage as ChatMessage;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addImageAndCreateChatByUsers(
    userSenderId: string,
    userReceivingId: string,
    imageUrl: string
  ): Promise<ChatMessage> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.addChatMessage,
        {
          type: ChatMessageType.image,
          userId: userSenderId,
          userReceivingId,
          message: imageUrl
        }
      );

      if (result.addChatMessage) {
        return result.addChatMessage as ChatMessage;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getUserChats(userId: string): Promise<Chat[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getUserChats,
        {
          type: ChatMessageType.message,
          userId,
          orderBy: 'updatedAt',
          direction: 'desc'
        }
      );

      const items: Chat[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          let chat = item as Chat;

          for (const user of chat.users) {
            if (this.currentUser && user.id !== this.currentUser.id) {
              chat = Object.assign({ otherUser: user }, chat);
              break;
            }
          }

          items.push(chat);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getUnreadChatForCurrentUser(): Promise<number> {
    if (this.currentUser) {
      const count: number = await this.getUnreadChatForUser(this.currentUser.id);

      this.setCountCurrentUserChatsUnread(count);

      return count;
    } else {
      return null;
    }
  }

  async getUnreadChatForUser(userId: string): Promise<number> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.countUnreadChats,
        {
          userId
        }
      );

      if (result.countUnreadChats) {
        return parseInt(result.countUnreadChats, 10);
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async setChatMessageAsRead(chatId: string, userId: string): Promise<Chat> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.setChatMessageAsRead,
        {
          chatId,
          userId
        }
      );

      if (result.setChatMessageAsRead) {
        let chat: Chat = result.setChatMessageAsRead as Chat;

        for (const user of chat.users) {
          if (this.currentUser && user.id !== this.currentUser.id) {
            chat = Object.assign({ otherUser: user }, chat);
            break;
          }
        }

        return chat;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}

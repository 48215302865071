/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, NgZone, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router } from '@angular/router';

import { UserService } from '../services/user.service';
import { SideMenuService } from '../services/side-menu.service';

import { AlertController, NavController } from '@ionic/angular';
import { ForbiddenWordService } from '../services/forbidden-word.service';
import { ForbiddenWord } from '../models/forbidden-word.interface';
import { ExtendUserCredential } from '../models/user-credential.interface';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss']
})
export class RegisterPage implements OnInit {
  form: FormGroup;
  sending = false;

  userCredential: ExtendUserCredential;

  showPassword = false;
  passwordInputType = 'password';

  showConfirmPassword = false;
  confirmPasswordInputType = 'password';
  sponsorUserId: string;

  forbiddenWords: ForbiddenWord[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private sideMenuService: SideMenuService,
    private navController: NavController,
    private alertController: AlertController,
    private zone: NgZone,
    private forbiddenWordService: ForbiddenWordService
  ) {}

  ngOnInit() {
    this.sponsorUserId = window.localStorage.getItem('idSponsorUser') ? window.localStorage.getItem('idSponsorUser') : null;
    const emailFilledIn: string = window.localStorage.getItem('emailFilledIn');
    window.localStorage.removeItem('emailFilledIn');
    this.userCredential = JSON.parse(window.localStorage.getItem('userCredential')) as ExtendUserCredential;
    window.localStorage.removeItem('userCredential');

    this.form = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      newsletterEnabled: [true]
    }, { validator: this.checkPasswords });

    if (this.userCredential) {
      const profile = this.userCredential.additionalUserInfo?.profile;
      if (profile) {
        this.form.get('firstname').setValue(profile.given_name);
        this.form.get('lastname').setValue(profile.family_name);
      }
      this.form.get('email').setValue(this.userCredential.user.email);

      for (const field of ['password', 'confirmPassword']) {
        this.form.get(field).clearValidators();
        this.form.get(field).updateValueAndValidity();
      }
    } else if (emailFilledIn) {
      this.form.get('email').setValue(emailFilledIn);

      for (const field of ['password', 'confirmPassword']) {
        this.form.get(field).setValidators([Validators.required, Validators.minLength(6)]);
        this.form.get(field).updateValueAndValidity();
      }
    }

    this.loadForbiddenWords();
  }

  async submitForm(): Promise<void> {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      if (
        await this.forbiddenWordService.hasForbiddenWords(
          this.form.value.username.toLowerCase(),
          true
        )
      ) {
        const alertEl = await this.alertController.create({
          cssClass: 'custom-alert',
          header: 'Oups !',
          message: 'Le pseudonyme contient des mots interdits par spotNcharge.',
          buttons: [
            {
              text: 'Ok',
              cssClass: 'danger'
            }
          ]
        });

        await alertEl.present();
      } else {
        // We save
        let data = Object.assign({}, this.form.value);
        data = Object.assign(data, { sponsorUserId: this.sponsorUserId });
        if (this.sponsorUserId) {
          window.localStorage.removeItem('sponsorUserId');
        }

        delete data.password;

        this.sending = true;

        this.form.disable();

        if (await this.userService.getWithUsername(data.username)) {
          this.sending = false;
          this.form.enable();

          alert(
            'Le pseudo ' + data.username + ' est déjà utilisé. Tu dois en choisir un autre. 😉'
          );
        } else {
          try {
            if (this.userCredential) {
              await this.userService.registerWithUserCredential(data, this.userCredential);
            } else {
              await this.userService.register(data, this.form.value.password);
            }

            this.zone.run(async () => {
              await this.userService.signOut(true);

              setTimeout(async () => {
                await this.userService.refreshCurrentUser();

                const message =
                  // eslint-disable-next-line @typescript-eslint/quotes
                  'Un lien de validation a été envoyé par email, clique sur ce lien pour valider ton compte et pouvoir te connecter. 😉';
                const alert = await this.alertController.create({
                  message,
                  buttons: ['OK']
                });

                await alert.present();

                this.sending = false;
                this.form.enable();
                this.form.reset();

                this.sideMenuService.goToRoot();

                this.sideMenuService.closeMenu();
                this.navController.navigateRoot(['/spot']);
              }, 1000);
            });
          } catch (err) {
            //console.log(err);

            const errMessage = err.message ?? err;

            alert(errMessage);

            this.sending = false;
            this.form.enable();
          }
        }
      }
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.passwordInputType = this.showPassword ? 'text' : 'password';
  }

  toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
    this.confirmPasswordInputType = this.showConfirmPassword ? 'text' : 'password';
  }

  goBack(): void {
    this.sideMenuService.navigateBack();
  }

  goForward(page: string): void {
    this.sideMenuService.navigateForward(page);
  }

  goToRoot(): void {
    this.sideMenuService.goToRoot();
  }

  async loadForbiddenWords(): Promise<void> {
    this.forbiddenWords = await this.forbiddenWordService.getAllOnce();
  }

  private checkPasswords(group: FormGroup): object {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSamePassword: true };
  }
}

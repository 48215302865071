export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAy5Ndn0tGOVqYe-hdzoFMZaLhTitdVC0U',
    authDomain: 'spotncharge-dev.firebaseapp.com',
    projectId: 'spotncharge-dev',
    storageBucket: 'spotncharge-dev.appspot.com',
    messagingSenderId: '407022587200',
    appId: '1:407022587200:web:7c0b9a12571c169352bea5'
  },
  wordpress: {
    apiUrl: 'https://spotncharge.com/wp-json/wp/v2/',
    authUrl: 'https://spotncharge.com/wp-json/jwt-auth/v1/token',
    gravityForms: {
      url: 'https://spotncharge.com/wp-json/gf/v2/',
      username: 'ck_ac682b9b7a6003ebb1585809a7755cd43094f4fe',
      password: 'cs_3258942e65e69d9efbc1460a3b951886049c1bdf'
    },
    login: {
      username: '',
      password: ''
    }
  },
  appVersion: '2.2.4'
};

import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser'; // Import de Capacitor Browser

@Injectable({
  providedIn: 'root'
})
export class UrlOpenerService {
  constructor(private platform: Platform) {}

  async openUrl(url: string): Promise<void> {
    if (!this.platform.is('capacitor')) {
      try {
        // Utilisation de Capacitor Browser pour ouvrir l'URL dans une vue native
        await Browser.open({ url });
      } catch (error) {
        //console.error('Error opening URL with Browser plugin', error);
        window.open(url, '_system'); // Fallback en cas d'erreur
      }
    } else {
      // Si la plateforme n'est pas hybride, ouvrir dans une nouvelle fenêtre
      window.open(url, '_blank');
    }
  }

  openUrlWithSystem(url: string): void {
    // Redirection du navigateur vers l'URL spécifiée
    window.location.href = url;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserCar } from 'src/app/models/user-car.interface';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  filterCounts: { [blockName: string]: { [filterName: string]: number } } = {};
  private totalFiltersSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private compatibleWithVehicleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private resetCompatibleWithVehicleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private selectedEmspsIdSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private previousUserCarSubject: BehaviorSubject<UserCar> = new BehaviorSubject<UserCar>(null);

  totalFilters$:Observable<number> = this.totalFiltersSubject.asObservable();
  compatibleWithVehicle$:Observable<boolean> = this.compatibleWithVehicleSubject.asObservable();
  resetCompatibleWithVehicle$:Observable<boolean> = this.resetCompatibleWithVehicleSubject.asObservable();
  selectedEmspsId$: Observable<string[]> = this.selectedEmspsIdSubject.asObservable();
  previousUserCar$: Observable<UserCar> = this.previousUserCarSubject.asObservable();

  constructor() {}

  setTotalFilters(value: number): void {
    this.totalFiltersSubject.next(value);
  }

  getTotalFilters(): Observable<number> {
    return this.totalFiltersSubject.asObservable();
  }

  setCompatibleWithVehicle(value: boolean): void {
    this.compatibleWithVehicleSubject.next(value);
  }

  getCompatibleWithVehicle(): boolean {
    return this.compatibleWithVehicleSubject.value;
  }

  setResetCompatibleWithVehicle(value: boolean): void {
    this.resetCompatibleWithVehicleSubject.next(value);
  }

  getResetCompatibleWithVehicle(): boolean {
    return this.resetCompatibleWithVehicleSubject.value;
  }

  setSelectedEmspsId(value: string[]): void {
    this.selectedEmspsIdSubject.next(value);
  }

  getSelectedEmspsId(): string[] {
    return this.selectedEmspsIdSubject.value;
  }

  setPreviousUserCar(value: UserCar): void {
    this.previousUserCarSubject.next(value);
  }

  getPreviousUserCar(): UserCar {
    return this.previousUserCarSubject.value;
  }
  
  toggleFilterCount(blockName: string, filterName: string, filterValue: string = null): void {
    if (!this.filterCounts[blockName]) {
      this.filterCounts[blockName] = {};
    }

    const groupFilters = this.filterCounts[blockName];
    const filterKey = filterValue ? `${filterName}_${filterValue}` : filterName;

    if (groupFilters[filterKey]) {
      delete groupFilters[filterKey];
    } else {
      groupFilters[filterKey] = 1;
    }
  }

  getTotalFiltersCount(): number {
    let total = 0;
    const filterCounts = this.filterCounts;

    for (const groupName in filterCounts) {
      const groupFilters = filterCounts[groupName];
      if (groupFilters) {
        total += Object.keys(groupFilters).length;
      }
    }

    return total;
  }

  updateTotalFilters(): void {
    let total = 0;
    for (const group in this.filterCounts) {
      if (this.filterCounts[group]) {
        total += Object.keys(this.filterCounts[group]).length;
      }
    }
    this.setTotalFilters(total);
  }
    
  getTotalFiltersInGroup(groupName: string): number {
    const groupCounts = this.filterCounts[groupName];
    if (groupCounts) {
        return Object.keys(groupCounts).length;
    } else {
        return 0;
    }
  }

  addFilterToCount(blockName: string, filterName: string, filterValue: string): void {
    if (!this.filterCounts[blockName]) {
        this.filterCounts[blockName] = {};
    }

    const groupFilters = this.filterCounts[blockName];
    const filterKey = filterValue ? `${filterName}_${filterValue}` : filterName;

    if (!groupFilters[filterKey]) {
        groupFilters[filterKey] = 1;
    }
  }

  removeFilterCount(blockName: string, filterName: string, filterValue: string = null): void {
    if (!this.filterCounts[blockName]) {
      return;
    }
  
    const groupFilters = this.filterCounts[blockName];
    const filterKey = filterValue ? `${filterName}_${filterValue}` : filterName;
  
    if (groupFilters[filterKey]) {
      delete groupFilters[filterKey];
    }
  }
  
  resetFilter(blockName: string): void {
    if (this.filterCounts[blockName]) {
      delete this.filterCounts[blockName];
    }
  }

  resetFilters(): void {
    this.filterCounts = {};
  }

  initializeFilters(): void {
    this.compatibleWithVehicleSubject.next(false);
    this.selectedEmspsIdSubject.next([]);
    this.resetFilter('operator');
    this.resetFilters();
    this.resetObservables();
    this.updateTotalFilters();
  }

  resetObservables(): void {
    this.totalFiltersSubject.next(0);
    this.compatibleWithVehicleSubject.next(false);
    this.selectedEmspsIdSubject.next([]);
  }
}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { AppSetting } from '../models/app-setting.interface';

import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {
  collectionNamePlural = 'appSettings';
  collectionNameSingular = 'appSetting';

  grahqlQueries: {
    getAllItems: string;
    getItem: string;
    getItemByCode: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
  } = {
    getAllItems: `
	query getAllItems($query: String, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String) {
		appSettings (
          query: $query
          page: $page,
          itemsPerPage: $itemsPerPage,
          orderBy: $orderBy,
          direction: $direction  
        ) {
          id
          code
          value
          description
		    }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    appSetting(id: $id) {
				id
				code
                value
                description
		    }
		  }
		`,
    getItemByCode: `
		  query getItemByCode($code: String!) {
		    appSetting(code: $code) {
				id
				code
                value
                description
		    }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteAppSetting(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem($id: ID!, $code: String!, $value: String, $description: String) {
				updateAppSetting(id: $id, code: $code, value: $value, description: $description) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem($code: String!, $value: String, $description: String) {
				addAppSetting(code: $code, value: $value, description: $description) {
					id
				}
			}
		`
  };

  constructor(private graphqlService: GraphqlService) {}

  async add(data: AppSetting): Promise<AppSetting> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addAppSetting) {
        return result.addAppSetting as AppSetting;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<AppSetting> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as AppSetting;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromCode(code: string): Promise<AppSetting> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getItemByCode,
        {
          code
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as AppSetting;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: AppSetting): Promise<AppSetting> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as AppSetting;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<AppSetting[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: AppSetting[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as AppSetting);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async search(query: string): Promise<AppSetting[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        { query }
      );

      const items: AppSetting[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as AppSetting);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}

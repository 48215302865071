import { GravityFormEntry } from '../services/gravity-forms.service';
import { UserGravityFormEntry } from './user-gravity-form-entry.interface';

export interface QuotationProduct {
  id: string;
  description: string;
  qty: number;
  amountHtPerUnit: number;
  amountHt: number;
  tvaRate: number;
  amountTva: number;
  amountTtc: number;
  currency: string;
}

export interface Quotation {
  id: string;
  userId: string;
  userGravityFormEntryId: string;
  reference: string;
  currency: CurrenciesList;
  products: QuotationProduct[];
  amountHtTotal: number;
  amountTvaTotal: number;
  amountTtcTotal: number;
  status: string;
  dateTechnicalVisitPlanned: string;
  dateInstallPlanned: string;
  dateOnlinePlanned: string;
  dateTechnicalVisitReal: string;
  dateInstallReal: string;
  dateOnlineReal: string;
  statusUpdatedAt: any;
  createdAt: Date;
  updatedAt: Date;

  userGravityFormEntry?: UserGravityFormEntry;
  gravityFormEntry?: GravityFormEntry;
}

export interface QuotationRequest {
  userGravityFormEntry?: UserGravityFormEntry;
  gravityFormEntry?: GravityFormEntry;
  quotation?: Quotation;
  countQuotationSent: number;
}

export enum TvaList {
  noTva = 0,
  reduced = 5.5,
  full = 20
}

export enum CurrenciesList {
  eur = 'EUR'
}
export enum QuotationStatusList {
  proposed = 'proposed',
  acceptedByRequestingUser = 'acceptedByRequestingUser',
  rejectedByRequestingUser = 'rejectedByRequestingUser',
  rejectedByQuotationAuthor = 'rejectedByQuotationAuthor',
  approvedByQuotationAuthor = 'approvedByQuotationAuthor'
}

export const getQuotationStatusTitle = (status: QuotationStatusList): string => {
  switch (status) {
    case QuotationStatusList.proposed:
      return 'Proposé';
      break;
    case QuotationStatusList.acceptedByRequestingUser:
      return 'Accepté par le demandeur';
      break;
    case QuotationStatusList.rejectedByRequestingUser:
      return 'Rejeté';
      break;
    case QuotationStatusList.rejectedByQuotationAuthor:
      return 'Rejeté par le professionnel';
      break;
    case QuotationStatusList.approvedByQuotationAuthor:
      return 'Approuvé par le professionnel';
      break;
    default:
      return status;
      break;
  }
};

export const DEFAULT_TVA_RATE = TvaList.full;
export const DEFAULT_CURRENCY = CurrenciesList.eur;
export const DEFAULT_QUOTATION_STATUS = QuotationStatusList.proposed;

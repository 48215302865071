import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { ChargingStationSuggestion } from '../models/charging-station-suggestion.interface';

import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class ChargingStationSuggestionService {
  collectionNamePlural = 'chargingStationSuggestions';
  collectionNameSingular = 'chargingStationSuggestion';

  grahqlQueries: {
    getAllItems: string;
    getItem: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
  } = {
    getAllItems: `
    query getAllItems($query: String, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String) {
        chargingStationSuggestions (
          query: $query
          page: $page,
          itemsPerPage: $itemsPerPage,
          orderBy: $orderBy,
          direction: $direction  
        ) {
          id
          address
          userId

          user {
            id
            fullName
          }
		    }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    chargingStationSuggestion(id: $id) {
          id
          address
          userId
    
          user {
            id
            fullName
          }
		    }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteChargingStationSuggestion(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem(
        $id: ID!, 
        $address: JSON!, 
        $userId: String!, 
      ) {
				updateChargingStationSuggestion(
          id: $id, 
          address: $address, 
          userId: $userId
        ) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem(
        $address: JSON!, 
        $userId: String!, 
      ) {
				addChargingStationSuggestion(
          address: $address, 
          userId: $userId
        ) {
					id
				}
			}
		`
  };

  constructor(private graphqlService: GraphqlService) {}

  async add(data: ChargingStationSuggestion): Promise<ChargingStationSuggestion> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addChargingStationSuggestion) {
        return result.addChargingStationSuggestion as ChargingStationSuggestion;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<ChargingStationSuggestion> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as ChargingStationSuggestion;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: ChargingStationSuggestion): Promise<ChargingStationSuggestion> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as ChargingStationSuggestion;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<ChargingStationSuggestion[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: ChargingStationSuggestion[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ChargingStationSuggestion);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async search(query: string): Promise<ChargingStationSuggestion[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        { query }
      );

      const items: ChargingStationSuggestion[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ChargingStationSuggestion);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}

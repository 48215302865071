/* eslint-disable prefer-arrow/prefer-arrow-functions */
export enum GamificationType {
  // Spot
  addPictureToChargingStation = 'addPictureToChargingStation', // DONE
  deletePictureToChargingStation = 'deletePictureToChargingStation', // DONE

  suggestChargingStation = 'suggestChargingStation', // DONE

  addChargingStationToFavorite = 'addChargingStationToFavorite', // DONE
  removeChargingStationToFavorite = 'removeChargingStationToFavorite', // DONE
  deleteChargingStationToFavorite = 'deleteChargingStationToFavorite', // DONE

  signalerInChargingStation = 'signalerInChargingStation', // DONE

  leaveCommentToChargingStation = 'leaveCommentToChargingStation', // DONE

  addReviewToChargingStation = 'addReviewToChargingStation', // DONE
  deleteReviewToChargingStation = 'deleteReviewToChargingStation', // DONE

  suggestModificationToChargingStation = 'suggestModificationToChargingStation', // DONE

  openUrlSpotncharge = 'openUrlSpotncharge', // DONE
  openUrlEmsp = 'openUrlEmsp', // DONE

  // Itinerary
  setItinerary = 'setItinerary', // DONE
  saveItinerary = 'saveItinerary', // DONE
  shareItinerary = 'shareItinerary', // DONE
  openItineraryInNavigationApp = 'openItineraryInNavigationApp', // DONE

  // Reservation
  newReservationOfChargingStation = 'newReservationOfChargingStation', // DONE
  endedReservationOfChargingStation = 'endedReservationOfChargingStation', // DONE

  // Charge
  calculateTimeSimulator = 'calculateTimeSimulator', // DONE
  calculateCostSimulator = 'calculateCostSimulator', // DONE
  saveTimeSimulator = 'saveTimeSimulator', // DONE
  saveCostSimulator = 'saveCostSimulator', // DONE
  shareTimeSimulator = 'shareTimeSimulator', // DONE
  shareCostSimulator = 'shareCostSimulator', // DONE


  // Marketplace
  createAdToMarketplace = 'createAdToMarketplace', // DONE
  deleteAdFromMarketplace = 'deleteAdFromMarketplace', // DONE
  signalementForAdInMarketplace = 'signalementForAdInMarketplace', // DONE
  addAdFromMarketplaceToFavorite = 'addAdFromMarketplaceToFavorite', // DONE
  removeAdFromMarketplaceToFavorite = 'removeAdFromMarketplaceToFavorite',
  deleteAdFromMarketplaceToFavorite = 'deleteAdFromMarketplaceToFavorite',

  // News
  addCommentToNewsArticle = 'addCommentToNewsArticle', // DONE
  shareNewsArticle = 'shareNewsArticle', // DONE

  // Community
  addMessageToStream = 'addMessageToStream', // DONE
  addCommentToStreamMessage = 'addCommentToStreamMessage', // DONE

  // UserCar
  addUserCar = 'addUserCar', // DONE
  removeUserCar = 'removeUserCar', // DONE
  deleteUserCar = 'deleteUserCar', // DONE
  addItineraryToUserCar = 'addItineraryToUserCar', // DONE

  // UserChargingStation
  addUserChargingStation = 'addUserChargingStation', // DONE
  deleteUserChargingStation = 'deleteUserChargingStation', // DONE
  newReservationUserChargingStation = 'newReservationUserChargingStation', // DONE
  endedReservationUserChargingStation = 'endedReservationUserChargingStation', // DONE

  // User
  addProfilPicture = 'addProfilPicture', // DONe
  removeProfilPicture = 'removeProfilPicture', // DONE
  deleteProfilPicture = 'deleteProfilPicture', // DONE
  profilCompleted = 'profilCompleted', // DONE
  registerNewsletters = 'registerNewsletters' // DONE
}

export interface Gamification {
  id: string;
  code: GamificationType;
  value: number;
  delayInSec: number;
  createdAt: Date;
  updatedAt: Date;
}

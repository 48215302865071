import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { UserViewHeaderSnippetComponent } from './user-view-header-snippet.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule],
  declarations: [UserViewHeaderSnippetComponent],
  exports: [
    UserViewHeaderSnippetComponent,
    FontAwesomeModule
  ]
})
export class UserViewHeaderSnippetComponentModule {}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {
  constructor(private apollo: Apollo) {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  apolloWatchQuery(query: string, variables: object = {}): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apollo
        .watchQuery<any>({
          query: gql(query),
          variables,
          errorPolicy: 'all',
          fetchPolicy: 'network-only'
        })
        .valueChanges.subscribe(
          (response: any) => {
            if (response.errors && response.errors.length) {
              const messages: Array<string> = [];

              for (const error of response.errors) {
                messages.push(error.message);
              }

              console.error(messages);
            }

            resolve(response.data);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  apolloWatchQueryObservable(query: string, variables: any = {}): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: gql(query),
        variables,
        context: {
          queryDeduplication: false
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(map(({ data }) => data));
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  apolloMutate(mutation: string, variables: object = {}): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: gql(mutation),
          variables,
          errorPolicy: 'all',
          update: (store: any, response: any) => {
            if (response.errors && response.errors.length) {
              const messages: Array<string> = [];

              for (const error of response.errors) {
                messages.push(error.message);
              }

              reject(messages);
            } else {
              resolve(response.data);
            }
          }
        })
        .subscribe();
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  apolloSubscription(query: string, variables: object = {}): Observable<any> {
    return this.apollo.subscribe<any>({
      query: gql(query),
      variables,
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    });
  }
}

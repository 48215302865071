import { Injectable, NgZone } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { UserService } from './user.service';
import { SideMenuService } from './side-menu.service';

import { SubscribePage } from '../subscribe/subscribe.page';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAppService implements CanActivate {
  constructor(
    private navCtrl: NavController,
    private sideMenuService: SideMenuService,
    private userService: UserService,
    private router: Router,
    private zone: NgZone,
    private alertController: AlertController,
    private modalController: ModalController,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      // Utilisez firstValueFrom pour attendre la première valeur de l'observable
      const isLogged = await firstValueFrom(this.userService.isLoggedObservable.pipe(
        filter(value => value !== null) // Assurez-vous que la valeur n'est pas null
      ));

      if (!isLogged) {
        //await this.openLogInAlert('Tu dois être membre pour utiliser cette fonctionnalité. 😉');
        await this.openSubscribeModal();
        return false;
      }
      
      return true;
    } catch (error) {
      // En cas d'erreur, on suppose que l'utilisateur n'est pas connecté
      await this.openSubscribeModal();
      return false;
    }
  }

  async openSubscribeModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: SubscribePage,
    });
  
    await modal.present();
  }
}

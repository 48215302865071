import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { DEFAULT_QUOTATION_STATUS, Quotation } from '../models/quotation.interface';

import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  collectionNamePlural = 'quotations';
  collectionNameSingular = 'quotation';

  grahqlQueries: {
    getAllItems: string;
    getItem: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
    paymentProFee: string;
    countForQuotationRequest: string;
  } = {
    getAllItems: `
		  query getAllItems(
              $query: String, 
              $page: Int, 
              $itemsPerPage: Int, 
              $orderBy: String, 
              $direction: String, 
              $userId: String, 
              $userGravityFormEntryId: String
            ) {
		    quotations (
          query: $query
          page: $page,
          itemsPerPage: $itemsPerPage,
          orderBy: $orderBy,
          direction: $direction,
          userId: $userId,
          userGravityFormEntryId: $userGravityFormEntryId
        ) {
          id
          userId
          userGravityFormEntryId
          reference
          currency
          products {
            id
            description
            qty
            amountHtPerUnit
            amountHt
            tvaRate
            amountTva
            amountTtc
            currency            
          }
          amountHtTotal
          amountTvaTotal
          amountTtcTotal
          status
          dateTechnicalVisitPlanned
          dateInstallPlanned
          dateOnlinePlanned
          dateTechnicalVisitReal
          dateInstallReal
          dateOnlineReal

          userGravityFormEntry {
            id
            entryId
          }
          statusUpdatedAt
          createdAt
		    }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    quotation(id: $id) {
				id
          userId
          userGravityFormEntryId
          reference
          currency
          products {
            id
            description
            qty
            amountHtPerUnit
            amountHt
            tvaRate
            amountTva
            amountTtc
            currency            
          }
          amountHtTotal
          amountTvaTotal
          amountTtcTotal
          status
          dateTechnicalVisitPlanned
          dateInstallPlanned
          dateOnlinePlanned
          dateTechnicalVisitReal
          dateInstallReal
          dateOnlineReal
          statusUpdatedAt
          createdAt
		    }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteQuotation(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem($id: ID!, 
                $userId: String
                $userGravityFormEntryId: String
                $reference: String
                $currency: String
                $products: [JSON]
                $amountHtTotal: Float
                $amountTvaTotal: Float
                $amountTtcTotal: Float,
                $status: String,
                $dateTechnicalVisitPlanned: String,
                $dateInstallPlanned: String,
                $dateOnlinePlanned: String, 
                $dateTechnicalVisitReal: String,
                $dateInstallReal: String,
                $dateOnlineReal: String, 
                $statusUpdatedAt: JSON  
            ) {
				updateQuotation(id: $id, 
                    userId: $userId
                    userGravityFormEntryId: $userGravityFormEntryId
                    reference: $reference
                    currency: $currency
                    products: $products
                    amountHtTotal: $amountHtTotal
                    amountTvaTotal: $amountTvaTotal
                    amountTtcTotal: $amountTtcTotal,
                    status: $status,
                    dateTechnicalVisitPlanned: $dateTechnicalVisitPlanned,
                    dateInstallPlanned: $dateInstallPlanned,
                    dateOnlinePlanned: $dateOnlinePlanned,
                    dateTechnicalVisitReal: $dateTechnicalVisitReal,
                    dateInstallReal: $dateInstallReal,
                    dateOnlineReal: $dateOnlineReal,
                        statusUpdatedAt: $statusUpdatedAt
            ) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem(
                $userId: String!
                $userGravityFormEntryId: String!
                $reference: String!
                $currency: String!
                $products: [JSON]!
                $amountHtTotal: Float!
                $amountTvaTotal: Float!
                $amountTtcTotal: Float!, 
                $status: String,
                $dateTechnicalVisitPlanned: String,
                $dateInstallPlanned: String,
                $dateOnlinePlanned: String,
                $dateTechnicalVisitReal: String,
                $dateInstallReal: String,
                $dateOnlineReal: String, 
                $statusUpdatedAt: JSON
            ) {
				addQuotation(
                    userId: $userId
                    userGravityFormEntryId: $userGravityFormEntryId
                    reference: $reference
                    currency: $currency
                    products: $products
                    amountHtTotal: $amountHtTotal
                    amountTvaTotal: $amountTvaTotal
                    amountTtcTotal: $amountTtcTotal,
                    status: $status,
                    dateTechnicalVisitPlanned: $dateTechnicalVisitPlanned,
                    dateInstallPlanned: $dateInstallPlanned,
                    dateOnlinePlanned: $dateOnlinePlanned,
                    dateTechnicalVisitReal: $dateTechnicalVisitReal,
                    dateInstallReal: $dateInstallReal,
                    dateOnlineReal: $dateOnlineReal,
                    statusUpdatedAt: $statusUpdatedAt
               ) {
					id
				}
			}
		`,
    paymentProFee: `
    mutation paymentProFee(
              $userId: String!
              $quotationId: String!,
              $paymentMethod: String!
          ) {
            paymentProFee(
              userId: $userId
              quotationId: $quotationId,
              paymentMethod: $paymentMethod
             ) {
              id
              userId
              userGravityFormEntryId
              reference
              currency
              products {
                id
                description
                qty
                amountHtPerUnit
                amountHt
                tvaRate
                amountTva
                amountTtc
                currency            
              }
              amountHtTotal
              amountTvaTotal
              amountTtcTotal
              status
              dateTechnicalVisitPlanned
              dateInstallPlanned
              dateOnlinePlanned
              dateTechnicalVisitReal
              dateInstallReal
              dateOnlineReal
              statusUpdatedAt
              createdAt
      }
    }
  `,
    countForQuotationRequest: `
    query countForQuotationRequest($userGravityFormEntryId: String!) {
      countForQuotationRequest(userGravityFormEntryId: $userGravityFormEntryId)
    }
  `
  };

  constructor(private graphqlService: GraphqlService) {}

  async add(data: Quotation): Promise<Quotation> {
    data.status = DEFAULT_QUOTATION_STATUS;

    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addQuotation) {
        return result.addQuotation as Quotation;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<Quotation> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Quotation;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: Quotation): Promise<Quotation> {
    //console.log(
      //'🚀 ~ file: quotation.service.ts ~ line 288 ~ QuotationService ~ update ~ data',
      //data
    //);
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Quotation;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<Quotation[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: Quotation[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Quotation);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async search(query: string): Promise<Quotation[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        { query }
      );

      const items: Quotation[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Quotation);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async paymentProFee(
    userId: string,
    quotationId: string,
    paymentMethod: string
  ): Promise<Quotation> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.paymentProFee, {
        userId,
        quotationId,
        paymentMethod
      });

      if (result.paymentProFee) {
        return result.paymentProFee as Quotation;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async countForQuotationRequest(userGravityFormEntryId: string): Promise<number> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.countForQuotationRequest,
        {
          userGravityFormEntryId
        }
      );

      if (typeof result.countForQuotationRequest !== 'undefined') {
        return result.countForQuotationRequest as number;
      }

      return 0;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}

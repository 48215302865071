import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { SideMenuPageRoutingModule } from './side-menu-routing.module';

import { SideMenuPage } from './side-menu.page';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdSnippetComponentModule } from '../components/ad-snippet/ad-snippet.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SideMenuPageRoutingModule,
    TranslateModule,
    FontAwesomeModule,
    AdSnippetComponentModule
  ],
  declarations: [SideMenuPage]
})
export class SideMenuPageModule {}

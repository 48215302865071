import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { Page, PageContainer } from '../models/page.interface';

import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  collectionNamePlural = 'pages';
  collectionNameSingular = 'page';

  grahqlQueries: {
    getAllItems: string;
    getAllItemsInContainer: string;
    getItem: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
  } = {
    getAllItems: `
      query getAllItems($query: String, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String, $container: String) {
        pages (
          page: $page,
          itemsPerPage: $itemsPerPage,
          orderBy: $orderBy,
          direction: $direction,
          query: $query,
          container: $container
        ) {
          id
          title
          slug
          subtitle
          icon
          description
          container
          type
          wordpressPageUrl
          gravityFormId
          callToActionEnabled
          callToActionTitle
          callToActionType
          callToActionWordpressPageUrl
          callToActionGravityFormId
          position
          pageVisibility
        }
		  }
		`,
    getAllItemsInContainer: `
      query getAllItemsInContainer($container: String!) {
        pages (
          container: $container
        ) {
          id
          title
          slug
          subtitle
          icon
          description
          type
          callToActionEnabled
          callToActionTitle
          callToActionType
          callToActionWordpressPageUrl
          callToActionGravityFormId
          position
          pageVisibility
        }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    page(id: $id) {
				id
				title
        slug
        subtitle
          icon
          description
                container
                type
                wordpressPageUrl
                gravityFormId
                callToActionEnabled
                callToActionTitle
          callToActionType
          callToActionWordpressPageUrl
          callToActionGravityFormId
          position
          pageVisibility
            }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deletePage(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem(
                $id: ID!, 
                $title: String!,
                $slug: String,
                $subtitle: String,
                $icon: String,
                $description: String,
                $container: String,
                $type: String
                $wordpressPageUrl: String,
                $gravityFormId: String,
                $callToActionEnabled: Boolean,
                $callToActionTitle: String,
                $callToActionType: String,
                $callToActionWordpressPageUrl: String,
                $callToActionGravityFormId: String,
                $position: Int,
                $pageVisibility: String
            ) {
				updatePage(
                    id: $id, 
                    title: $title,
                    slug: $slug,
                    subtitle: $subtitle,
                    icon: $icon,
                    description: $description,
                    container: $container,
                    type: $type,
                    wordpressPageUrl: $wordpressPageUrl,
                    gravityFormId: $gravityFormId,
                    callToActionEnabled: $callToActionEnabled,
                    callToActionTitle: $callToActionTitle,
                    callToActionType: $callToActionType,
                    callToActionWordpressPageUrl: $callToActionWordpressPageUrl
                    callToActionGravityFormId: $callToActionGravityFormId,
                    position: $position,
                    pageVisibility: $pageVisibility
                ) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem(
                $title: String!, 
                $slug: String,
                $subtitle: String,
                $icon: String,
                $description: String,
                $container: String,
                $type: String
                $wordpressPageUrl: String,
                $gravityFormId: String,
                $callToActionEnabled: Boolean,
                $callToActionTitle: String,
                $callToActionType: String,
                $callToActionWordpressPageUrl: String,
                $callToActionGravityFormId: String,
                $position: Int,
                $pageVisibility: String
            ) {
				addPage(
                    title: $title,
                    slug: $slug,
                    subtitle: $subtitle,
                    icon: $icon,
                    description: $description,
                    container: $container,
                    type: $type,
                    wordpressPageUrl: $wordpressPageUrl,
                    gravityFormId: $gravityFormId,
                    callToActionEnabled: $callToActionEnabled,
                    callToActionTitle: $callToActionTitle,
                    callToActionType: $callToActionType,
                    callToActionWordpressPageUrl: $callToActionWordpressPageUrl
                    callToActionGravityFormId: $callToActionGravityFormId,
                    position: $position,
                    pageVisibility: $pageVisibility
                ) {
					id
				}
			}
		`
  };

  constructor(private graphqlService: GraphqlService) {}

  async add(data: Page): Promise<Page> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addPage) {
        return result.addPage as Page;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<Page> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Page;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: Page): Promise<Page> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Page;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<Page[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: Page[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Page);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAllInContainer(container: PageContainer): Promise<Page[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        {
          container,
          orderBy: 'position',
          direction: 'asc'
        }
      );

      const items: Page[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Page);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async search(query: string): Promise<Page[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        { query }
      );

      const items: Page[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Page);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}

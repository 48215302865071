import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-take-photo',
  templateUrl: './take-photo.component.html',
  styleUrls: ['./take-photo.component.scss']
})
export class TakePhotoComponent implements OnInit {
  slideOpts = {
    spaceBetween: 10,
    centeredSlides: false,
    initialSlide: 0,
    pagination: false
  };

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  notImplemented() {
    alert('In developement');
  }

  submit() {
    this.close();
  }

  close() {
    this.modalController.dismiss();
  }
}

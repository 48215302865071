import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { sub } from 'date-fns';
import { Subscription } from 'rxjs';
import { Address } from 'src/app/models/address.interface';
import { ChargingStationSuggestion } from 'src/app/models/charging-station-suggestion.interface';
import { User } from 'src/app/models/user.interface';
import { ChargingStationSuggestionService } from 'src/app/services/charging-station-suggestion.service';
import { GeolocationService } from 'src/app/services/geolocation.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-spot-suggestion-station',
  templateUrl: './spot-suggestion-station.page.html',
  styleUrls: ['./spot-suggestion-station.page.scss']
})
export class SpotSuggestionStationPage implements OnInit, OnDestroy {
  form: FormGroup;
  updatingSearch = false;

  suggestions: google.maps.places.AutocompletePrediction[] = [];
  query: string;

  sending = false;

  currentUser: User;

  private subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private geolocationService: GeolocationService,
    private alertController: AlertController,
    private userService: UserService,
    private sideMenuService: SideMenuService,
    private chargingStationSuggestionService: ChargingStationSuggestionService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.userService.currentUserObservable.subscribe((currentUser: User) => {
        this.currentUser = currentUser;
      })
    );

    this.form = this.formBuilder.group({
      address: this.formBuilder.group({
        placeId: [null, [Validators.required]],
        longitude: [null],
        latitude: [null],
        formattedAddress: [null]
      })
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  close() {
    this.modalController.dismiss();
  }

  async updateQuery(query: string): Promise<void> {
    if (query !== this.form.value.address.formattedAddress) {
      this.form.get('address').get('placeId').setValue(null);
      this.form.get('address').get('latitude').setValue(null);
      this.form.get('address').get('longitude').setValue(null);
      this.form.get('address').get('formattedAddress').setValue(null);

      if (query.length) {
        this.suggestions = [];

        if (query.length > 2) {
          try {
            this.suggestions = await this.geolocationService.autocompleteAdress(query, 'fr');
          } catch (err) {
            //console.log(err);
            this.presentErrorAlert(err);
          }
        }
      }
    }
  }

  async presentErrorAlert(message: string): Promise<void> {
    const alert = await this.alertController.create({
      message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async selectSuggestion(suggestion: google.maps.places.AutocompletePrediction): Promise<void> {
    try {
      this.updatingSearch = true;

      const result: Address = await this.geolocationService.getPlaceDetails(suggestion.place_id);

      this.query = result.formattedAddress;

      this.form.get('address').get('placeId').setValue(result?.placeId);
      this.form.get('address').get('latitude').setValue(result?.latitude);
      this.form.get('address').get('longitude').setValue(result?.longitude);
      this.form.get('address').get('formattedAddress').setValue(result?.formattedAddress);

      //console.log(result);
    } catch (err) {
      //console.log(err);
      this.presentErrorAlert(err);
    } finally {
      this.updatingSearch = false;
    }

    this.suggestions = [];
  }

  openLoginPage(): void {
    this.sideMenuService.navigateForward('welcome');
    this.close();
  }

  async submitForm(): Promise<void> {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const data = Object.assign({}, this.form.value);
      this.form.disable();

      data.userId = this.currentUser.id;

      //console.log(data);
      try {
        const result: ChargingStationSuggestion = await this.chargingStationSuggestionService.add(
          data
        );

        this.form.enable();

        const alertEl = await this.alertController.create({
          cssClass: 'custom-alert',
          header: 'Succès',
          message: 'Sauvegarde réussie.',
          buttons: [
            {
              text: 'Ok',
              cssClass: 'success',
              handler: () => {
                this.close();
              }
            }
          ]
        });

        await alertEl.present();
      } catch (err) {
        //console.log(err);

        this.form.enable();

        const alertEl = await this.alertController.create({
          cssClass: 'custom-alert',
          header: 'Erreur',
          message: err.message,
          buttons: [
            {
              text: 'Ok',
              cssClass: 'danger',
              handler: () => {
                this.form.enable();
              }
            }
          ]
        });

        await alertEl.present();
      }
    }
  }
}

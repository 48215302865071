import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChargingStation } from 'src/app/models/charging-station.interface';
import { ReviewService } from 'src/app/services/review.service';

@Component({
  selector: 'app-evaluate',
  templateUrl: './evaluate.component.html',
  styleUrls: ['./evaluate.component.scss']
})
export class EvaluateComponent implements OnInit {
  selectedUpdateType;
  criterias = [
    {
      type: 'Emplacement',
      values: [
        {
          label: 'Station mal située',
          icon: 'map-marked-alt',
          impact: '-',
          selected: false
        },
        {
          label: 'Station isolée',
          icon: 'bus',
          impact: '-',
          selected: false
        }
      ]
    },
    {
      type: 'Disponibilité',
      values: [
        {
          label: 'Places occupées',
          icon: 'parking',
          impact: '-',
          selected: false
        },
        {
          label: 'Bornes en panne',
          icon: 'charging-station',
          impact: '-',
          selected: false
        },
        {
          label: 'Câble bloqué',
          icon: 'plug',
          impact: '-',
          selected: false
        }
      ]
    },
    {
      type: 'Qualité',
      values: [
        {
          label: 'Connexion aléatoire',
          icon: 'wifi',
          impact: '-',
          selected: false
        },
        {
          label: 'Badge non reconnu',
          icon: 'credit-card',
          impact: '-',
          selected: false
        },
        {
          label: 'Recharge incomplète',
          icon: 'battery-full',
          impact: '-',
          selected: false
        }
      ]
    },
    {
      type: 'Paiement',
      values: [
        {
          label: 'Tarification compliquée',
          icon: 'money-bill',
          impact: '-',
          selected: false
        },
        {
          label: 'Facturation érronée',
          icon: 'shopping-cart',
          impact: '-',
          selected: false
        }
      ]
    }
  ];
  message = '';
  loading = false;
  chargingStation: ChargingStation;

  constructor(private modalController: ModalController, private reviewService: ReviewService) {}

  ngOnInit() {
    this.loading = false;
  }

  async submit() {
    let rating = 5;

    this.criterias.forEach(criteriaGroup => {
      criteriaGroup.values.forEach(criteria => {
        if (criteria.selected && criteria.impact === '+') {
          //rating += 5 / 7;
        }
        if (criteria.selected && criteria.impact === '-') {
          rating -= 5 / 10;
        }
      });
    });

    try {
      await this.reviewService.addReviewFromCurrentUser(
        this.chargingStation.id,
        this.message,
        rating
      );
      this.modalController.dismiss(true);
    } catch (err) {
      //console.log(err);
      alert(err.message);
    }
  }

  close() {
    this.modalController.dismiss();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SuggestUpdateComponent } from './suggest-update.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, FormsModule, FontAwesomeModule, IonicModule],
  declarations: [SuggestUpdateComponent]
})
export class SuggestUpdateModule {}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { UserGamification } from '../models/user-gamification.interface';

import { GraphqlService } from './graphql.service';
import { UserService } from './user.service';
import { User } from '../models/user.interface';
import { GamificationType } from '../models/gamification.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserGamificationService {
  collectionNamePlural = 'userGamifications';
  collectionNameSingular = 'userGamification';

  grahqlQueries: {
    getAllItems: string;
    getItem: string;
    deleteItem: string;
    addItem: string;
    subscriptionUserGamification: string;
  } = {
    getAllItems: `
      query getAllItems(
        $query: String, $page: Int,
        $itemsPerPage: Int,
        $orderBy: String,
        $direction: String,
        $gamificationId: String,
        $userId: String
      ) {
        userGamifications (
          page: $page,
          itemsPerPage: $itemsPerPage,
          orderBy: $orderBy,
          direction: $direction,
          query: $query,
          gamificationId: $gamificationId,
          userId: $userId
        ) {
          id
          userId
          gamificationId
          gamificationCode
          createdAt

          gamification {
            code
          }
          nbPoints

          user {
            id
            fullName
          }
          }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    userGamification(id: $id) {
				id
        userId
        gamificationId
        gamificationCode
        createdAt

        gamification {
          code
        }
      nbPoints

        user {
          id
          fullName
        }
      }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteUserGamification(id: $id) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem($userId: String!, $gamificationCode: String) {
				addUserGamification(userId: $userId, gamificationCode: $gamificationCode) {
					id
				}
			}
		`,
    subscriptionUserGamification: `
		  subscription newUserGamification($currentUserId: ID!) {
		    newUserGamification(currentUserId: $currentUserId) {
          id
          userId
          gamificationId
          gamificationCode
          createdAt

          gamification {
            code
          }
          nbPoints
        }
		  }
		`
  };

  currentUser: User;

  constructor(private graphqlService: GraphqlService, private userService: UserService) {
    this.userService.currentUserObservable.subscribe(
      (currentUser: User) => (this.currentUser = currentUser)
    );
  }

  async add(data: any): Promise<UserGamification> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addUserGamification) {
        return result.addUserGamification as UserGamification;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addUserGamificationForCurrentUserWithGamificationCode(
    code: GamificationType
  ): Promise<UserGamification> {
    if (this.currentUser && !this.currentUser.isAdmin) {
      try {
        return await this.add({
          userId: this.currentUser.id,
          gamificationCode: code.toString()
        });
      } catch (err) {
        //console.log(err);

        return null;
      }
    } else {
      return null;
    }
  }

  async getFromId(id: string): Promise<UserGamification> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as UserGamification;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  // async update(data: UserGamification): Promise<UserGamification> {
  //   try {
  //     const result: any = await this.graphqlService.apolloMutate(
  //       this.grahqlQueries.updateItem,
  //       data
  //     );

  //     if (result[this.collectionNameSingular]) {
  //       return result[this.collectionNameSingular] as UserGamification;
  //     }
  //   } catch (err) {
  //     console.error(err);

  //     return Promise.reject(err);
  //   }
  // }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<UserGamification[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: UserGamification[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as UserGamification);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async search(query: string): Promise<UserGamification[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        { query }
      );

      const items: UserGamification[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as UserGamification);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  listenUserGamification(currentUserId: string): Observable<any> {
    return this.graphqlService.apolloSubscription(this.grahqlQueries.subscriptionUserGamification, {
      currentUserId
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { NavController, AlertController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../services/user.service';
import { SideMenuService } from '../services/side-menu.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss']
})
export class ForgotPasswordPage implements OnInit {
  form: FormGroup;
  sending = false;

  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private navCtrl: NavController,
    private userService: UserService,
    private sideMenuService: SideMenuService
  ) {}

  ngOnInit() {
    const emailFilledIn: string = window.localStorage.getItem('emailFilledIn');

    this.form = this.formBuilder.group({
      email: [emailFilledIn ? emailFilledIn : '', [Validators.required, Validators.email]]
    });
  }

  async submitForm(): Promise<void> {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      // We save
      const data = Object.assign({}, this.form.value);

      this.sending = true;

      this.form.disable();

      try {
        await this.userService.resetPassword(data.email);

        this.sending = false;
        this.form.enable();
        this.form.reset();

        const alertEl = await this.alertController.create({
          cssClass: 'custom-alert',
          header: "C'est parti 👍",
          message: 'Un email a été envoyé, vérifie ta boîte de réception et tes spams !',
          buttons: [
            {
              text: 'Ok',
              cssClass: 'success',
              handler: () => {
                this.navCtrl.navigateBack(['/login']);
              }
            }
          ]
        });

        await alertEl.present();
      } catch (err) {
        //console.log(err);

        alert(err.message);

        this.sending = false;
        this.form.enable();
      }
    }
  }

  goBack(): void {
    this.sideMenuService.navigateBack();
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardAppService } from './services/auth-guard-app.service';
import { AuthGuardNotLoggedAppService } from './services/auth-guard-not-logged.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'welcome',
    redirectTo: '/spot'
  },
  {
    path: 'login',
    redirectTo: '/spot'
  },
  {
    path: 'register',
    redirectTo: '/spot'
  },
  {
    path: 'set-password',
    loadChildren: () =>
      import('./set-password/set-password.module').then(m => m.SetPasswordPageModule),
    canActivate: [AuthGuardNotLoggedAppService]
  },
  {
    path: 'forgot-password',
    redirectTo: '/spot'
  },
  {
    path: 'spot',
    loadChildren: () =>
      import('./app/spot/spot-dashboard/spot-dashboard.module').then(m => m.SpotDashboardPageModule)
  },
  {
    path: 'didacticiels',
    loadChildren: () =>
      import('./didacticiels/didacticiels.module').then(m => m.DidacticielsPageModule)
  },
  {
    path: 'abonnements',
    canActivate: [AuthGuardAppService],
    children: [
      {
        path: '',
        redirectTo: 'select',
        pathMatch: 'full'
      },
      {
        path: 'select',
        loadChildren: () =>
          import('./subscriptions/selection/selection-abonnement.module').then(
            m => m.SelectionAbonnementPageModule
          )
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./subscriptions/payment/payment-abonnement.module').then(
            m => m.PaymentAbonnementPageModule
          )
      },
      {
        path: 'validation',
        loadChildren: () =>
          import('./subscriptions/validation/validation-abonnement.module').then(
            m => m.ValidationAbonnementPageModule
          )
      }
    ]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'become-pro',
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full'
      },
      {
        path: 'welcome',
        loadChildren: () =>
          import('./become-pro/become-pro-welcome/become-pro-welcome.module').then(
            m => m.BecomeProWelcomePageModule
          )
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./become-pro/become-pro-register/become-pro-register.module').then(
            m => m.BecomeProRegisterPageModule
          )
      },
      {
        path: 'register-infos',
        loadChildren: () =>
          import('./become-pro/become-pro-register-infos/become-pro-register-infos.module').then(
            m => m.BecomeProRegisterInfosPageModule
          )
      },
      {
        path: 'abonnements',
        children: [
          {
            path: '',
            redirectTo: 'select',
            pathMatch: 'full'
          },
          {
            path: 'select',
            loadChildren: () =>
              import(
                './become-pro/become-pro-subscriptions/subscription-pro-selection/subscription-pro-selection.module'
              ).then(m => m.SubscriptionProSelectionPageModule)
          },
          {
            path: 'payment',
            loadChildren: () =>
              import(
                './become-pro/become-pro-subscriptions/subscription-pro-payment/subscription-pro-payment.module'
              ).then(m => m.SubscriptionProPaymentPageModule)
          },
          {
            path: 'validation',
            loadChildren: () =>
              import(
                './become-pro/become-pro-subscriptions/subscription-pro-validation/subscription-pro-validation.module'
              ).then(m => m.SubscriptionProValidationPageModule)
          }
        ]
      }
    ]
  },
  {
    path: '**',
    loadChildren: () => import('./error404/error404.module').then(m => m.Error404PageModule)
  },
  {
    path: 'side-menu',
    loadChildren: () => import('./side-menu/side-menu.module').then(m => m.SideMenuPageModule)
  },
  {
    path: 'quotation-view',
    loadChildren: () =>
      import('./app/profile/quotations/quotation-view/quotation-view.module').then(
        m => m.QuotationViewPageModule
      )
  },
  {
    path: 'quotation-pro-approve',
    loadChildren: () =>
      import('./app/profile/quotations/quotation-pro-approve/quotation-pro-approve.module').then(
        m => m.QuotationProApprovePageModule
      )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

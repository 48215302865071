import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    getAuth,
    User as FirebaseUser,
    EmailAuthProvider, 
    reauthenticateWithCredential, 
    updateEmail,
    updatePassword,
    GoogleAuthProvider,
    signInWithPopup,
    OAuthProvider,
    FacebookAuthProvider,
    UserCredential
} from 'firebase/auth';
import { AlertController, Platform } from '@ionic/angular';

import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment.prod';

import { User } from '../models/user.interface';

import { GraphqlService } from './graphql.service';

// import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  collectionNamePlural = 'users';
  collectionNameSingular = 'user';

  grahqlQueries: {
    getAllItems: string;
    getItem: string;
    getItemWithFirebaseAuthId: string;
    getItemWithUsername: string;
    deleteItem: string;
    updateItem: string;
    updateBatteryChargingValues: string;
    updateEmspsValues: string;
    addItem: string;
    addFcmTokenToUser: string;
    doesUserExistsWithEmail: string;
    loginWithEmailAndFirebaseAuthId: string;
    updateFirebaseAuthId: string;
    updateField: string;
    updateStripeUserId: string;
    getConnectedAccountInfo: string;
    getConnectedAccountPayments: string;
    getWordpressToken: string;
    createStripeLoginLink: string;
    updateVerifiedEmailOfUser: string;
    getItemWithEmail: string;
    resendVerificationEmail: string;
  } = {
    getAllItems: `
    query users($query: String, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String, $isPro: Boolean, $isAdmin: Boolean, $emailIsVerified: Boolean, $newsletterEnabled: Boolean, $profilePictureExist: Boolean) {
      users(
        query: $query,
        page: $page,
        itemsPerPage: $itemsPerPage,
        orderBy: $orderBy,
        direction: $direction,
        isPro: $isPro,
        isAdmin: $isAdmin,
        emailIsVerified: $emailIsVerified,
        newsletterEnabled: $newsletterEnabled,
        profilePictureExist: $profilePictureExist
      ) {
          id
          firstname
          lastname
          username
          fullName
          stripeUserId
          email
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified
          createdAt
          lastLoginDate
          isLogged
          newsletterEnabled

          userBySponsorUserId {
            firstname
            lastname
            email
          }

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }
        }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    user(id: $id) {
          id
          firstname
          lastname
          username
          fullName
          stripeUserId
          email
          firebaseAuthIds
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          batteryChargeMinimum
          batteryChargeMaximum
          selectedEmspsId
          newsletterEnabled
          darkThemeEnabled
          gender
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          lastLoginDate
          isLogged
          createdAt
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified
          customerId
          stripeUserId

          userBySponsorUserId {
            firstname
            lastname
            email
          }

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }

		    }
		  }
		`,
    getConnectedAccountInfo: `
		  query getConnectedAccountInfo($accountId: String!) {
		    connectedAccountInfo(accountId: $accountId)
		  }
		`,
    getConnectedAccountPayments: `
		  query getConnectedAccountPayments($accountId: String!, $lastItem: String) {
		    connectedAccountPayments(accountId: $accountId, lastItem: $lastItem)
		  }
		`,
    getItemWithFirebaseAuthId: `
		  query getItemWithFirebaseAuthId($firebaseAuthId: String!) {
		    user(firebaseAuthId: $firebaseAuthId) {
          id
          customerId
          firstname
          lastname
          username
          stripeUserId
          fullName
          email
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          batteryChargeMinimum
          batteryChargeMaximum
          selectedEmspsId
          newsletterEnabled
          darkThemeEnabled
          gender
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          createdAt
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }


          userBadges {
            badge {
              id
              name
              imageUrl
            }
          }
		    }
		  }
		`,
    getItemWithUsername: `
		  query getItemWithUsername($username: String!, $userToIgnoreId: String) {
		    user(username: $username, userToIgnoreId: $userToIgnoreId) {
          id
          firstname
          lastname
          username
          stripeUserId
          fullName
          email
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          batteryChargeMinimum
          batteryChargeMaximum
          selectedEmspsId
          newsletterEnabled
          darkThemeEnabled
          gender
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          lastLoginDate
          isLogged
          createdAt
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }


          userBadges {
            badge {
              id
              name
              imageUrl
            }
          }
        }
		  }
		`,
    getItemWithEmail: `
		  query getItemWithUsername($email: String!) {
		    user(email: $email) {
          id
          firstname
          lastname
          username
          stripeUserId
          fullName
          email
          profilePictureUrl
          isAdmin
          messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          batteryChargeMinimum
          batteryChargeMaximum
          selectedEmspsId
          newsletterEnabled
          darkThemeEnabled
          gender
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          lastLoginDate
          isLogged
          createdAt
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified

          proMatchingQuotations {
            id
            userId
            fieldId
            value
          }


          userBadges {
            badge {
              id
              name
              imageUrl
            }
          }
        }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteUser(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem(
        $id: ID!,
        $firstname: String,
        $lastname: String,
        $username: String,
        $email: String,
        $customerId: String,
        $stripeUserId: String,
        $firebaseAuthIds: [String],
        $profilePictureUrl: String,
        $isAdmin: Boolean,
        $gender: String,
        $birthDate: String,
        $phone: String,
        $company: String,
        $description: String,
        $whoCanSendMeMessages: String,
        $whoCanInviteMeToBeFriend: String,
        $isPro: Boolean,
        $gravityFormEntryId: Int,
        $corporateName: String,
        $siren: String,
        $fonction: String,
        $address: JSON
    ) {
				updateUser(
          id: $id,
          firstname: $firstname,
          lastname: $lastname,
          username: $username,
          email: $email,
          customerId: $customerId,
          stripeUserId: $stripeUserId,
          firebaseAuthIds: $firebaseAuthIds,
          profilePictureUrl: $profilePictureUrl,
          isAdmin: $isAdmin,
          gender: $gender,
          birthDate: $birthDate,
          phone: $phone,
          company: $company,
          description: $description,
          whoCanSendMeMessages: $whoCanSendMeMessages,
          whoCanInviteMeToBeFriend: $whoCanInviteMeToBeFriend,
          isPro: $isPro,
          gravityFormEntryId: $gravityFormEntryId,
          corporateName: $corporateName,
          siren: $siren,
          fonction: $fonction,
          address: $address
        ) {
					id
				}
			}
		`,
    updateBatteryChargingValues: `
			mutation updateBatteryChargingValues($id: ID!, $batteryChargeMinimum: Int!, $batteryChargeMaximum: Int!) {
				updateBatteryChargingValues(id: $id, batteryChargeMinimum: $batteryChargeMinimum, batteryChargeMaximum: $batteryChargeMaximum) {
					id
				}
			}
		`,
    updateEmspsValues: `
    mutation updateEmspsValues($id: ID!, $selectedEmspsId: [String!]!) {
      updateEmspsValues(id: $id, selectedEmspsId: $selectedEmspsId) {
        id
      }
    }
  `,
    updateFirebaseAuthId: `
			mutation updateFirebaseAuthId($email: String!, $firebaseAuthId: String!) {
				updateFirebaseAuthId(email: $email, firebaseAuthId: $firebaseAuthId) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem(
        $firstname: String!,
        $lastname: String!,
        $username: String!,
        $email: String!,
        $firebaseAuthIds: [String],
        $gender: String,
        $birthDate: String,
        $phone: String,
        $company: String,
        $description: String,
        $whoCanSendMeMessages: String,
        $whoCanInviteMeToBeFriend: String,
        $isPro: Boolean,
        $gravityFormEntryId: Int,
        $corporateName: String,
        $siren: String,
        $fonction: String,
        $address: JSON,
        $newsletterEnabled: Boolean,
        $sponsorUserId: String
    ) {
				addUser(
          firstname: $firstname,
          lastname: $lastname,
          username: $username,
          email: $email,
          firebaseAuthIds: $firebaseAuthIds,
          gender: $gender,
          birthDate: $birthDate,
          phone: $phone,
          company: $company,
          description: $description,
          whoCanSendMeMessages: $whoCanSendMeMessages,
          whoCanInviteMeToBeFriend: $whoCanInviteMeToBeFriend,
          isPro: $isPro,
          gravityFormEntryId: $gravityFormEntryId,
          corporateName: $corporateName,
          siren: $siren,
          fonction: $fonction,
          address: $address,
          newsletterEnabled: $newsletterEnabled,
          sponsorUserId: $sponsorUserId,
        ) {
					id
				}
			}
		`,
    resendVerificationEmail: `
      mutation ResendVerificationEmail($email: String!) {
        resendVerificationEmail(email: $email) {
          id
          email
        }
      }
    `,
    addFcmTokenToUser: `
      mutation addFcmTokenToUser(
        $userId: String!,
        $token: String!,
    ) {
      addFcmTokenToUser(
          userId: $userId,
          token: $token,
        ) {
          id
        }
      }
    `,
    doesUserExistsWithEmail: `
			query doesUserExistsWithEmail($email: String!) {
				doesUserExistsWithEmail(email: $email)
			}
		`,
    loginWithEmailAndFirebaseAuthId: `
			query loginWithEmailAndFirebaseAuthId($email: String!, $firebaseAuthId: String!) {
				loginWithEmailAndFirebaseAuthId(email: $email, firebaseAuthId: $firebaseAuthId) {
					id
					firstname
					lastname
					username
					fullName
					email
					profilePictureUrl
					isAdmin
					messageLikesIds
          streamLikesIds
          annonceLikesIds
          userChargingStationFavoriteIds
          newsletterEnabled
          darkThemeEnabled
          gender
          stripeUserId
          birthDate
          phone
          company
          description
          whoCanSendMeMessages
          whoCanInviteMeToBeFriend
          isPro
          gravityFormEntryId
          corporateName
          siren
          fonction
          address
          totalPoints
          emailIsVerified
        }
			}
		`,

    updateField: `
			mutation updateUserField($id: ID!, $field: String!, $value: String!) {
				updateUserField(id: $id, field: $field, value: $value) {
					id
				}
			}
		`,
    updateStripeUserId: `
			mutation updateStripeUserId($id: ID!, $authorizationCode: String!) {
				updateStripeUserId(id: $id,authorizationCode: $authorizationCode) {
					id
				}
			}
		`,
    getWordpressToken: `
    query getWordpressToken($id: ID!, $password: String) {
      getWordpressToken(id: $id, password: $password)
    }
  `,
    createStripeLoginLink: `
      mutation createStripeLoginLink($id: ID!) {
        createStripeLoginLink(id: $id)
      }
    `,
    updateVerifiedEmailOfUser: `
    mutation updateVerifiedEmailOfUser($id: ID!) {
      updateVerifiedEmailOfUser(id: $id) {
        id
        emailIsVerified
      }
    }
  `
  };

  userAuth: FirebaseUser;
  userAuthObservable: Observable<FirebaseUser>;

  currentUser: User;
  currentUserObservable: Observable<User>;

  isLogged: boolean;
  isLoggedObservable: Observable<boolean>;

  darkThemeEnabled: boolean;
  darkThemeEnabledObservable: Observable<boolean>;

  private userAuthBehavior: BehaviorSubject<FirebaseUser>;
  private currentUserBehavior: BehaviorSubject<User>;
  private isLoggedBehavior: BehaviorSubject<boolean>;
  private darkThemeEnabledBehavior: BehaviorSubject<boolean>;

  constructor(
    private graphqlService: GraphqlService,
    private afAuth: AngularFireAuth,
    private platform: Platform,
    private alertController: AlertController
  ) {
    this.darkThemeEnabledBehavior = new BehaviorSubject<boolean>(null);
    this.darkThemeEnabledObservable = this.darkThemeEnabledBehavior.asObservable();
    this.darkThemeEnabledObservable.subscribe(
      (darkThemeEnabled: boolean) => (this.darkThemeEnabled = darkThemeEnabled)
    );

    this.userAuthBehavior = new BehaviorSubject<FirebaseUser>(null);
    this.userAuthObservable = this.userAuthBehavior.asObservable();
    this.userAuthObservable.subscribe((userAuth: FirebaseUser) => (this.userAuth = userAuth));

    this.currentUserBehavior = new BehaviorSubject<User>(null);
    this.currentUserObservable = this.currentUserBehavior.asObservable();
    this.currentUserObservable.subscribe((currentUser: User) => {
      this.currentUser = currentUser;

      if (this.currentUser && this.currentUser.darkThemeEnabled !== null) {
        this.setDarkThemeEnabled(this.currentUser.darkThemeEnabled);
      } else {
        this.setDarkThemeEnabled(null);
      }
    });

    this.isLoggedBehavior = new BehaviorSubject<boolean>(null);
    this.isLoggedObservable = this.isLoggedBehavior.asObservable();
    this.isLoggedObservable.subscribe((isLogged: boolean) => (this.isLogged = isLogged));

    this.afAuth.authState.subscribe((user: FirebaseUser) => {
      this.setUserAuth(user);
      this.refreshCurrentUser();
    });
  }

  private async handleAuthError(err: any): Promise<any> {
    switch (err.code) {
      case 'auth/weak-password':
        err.message = 'Le mot de passe n\'est pas suffisamment sécurisé.';
        break;
      case 'auth/user-mismatch':
        err.message = 'Compte utilisateur introuvable.';
        break;
      case 'auth/user-not-found':
        err.message = 'Compte utilisateur introuvable.';
        break;
      case 'auth/invalid-credential':
        err.message = 'L\'authentification du membre n\'est plus valide.';
        break;
      case 'auth/invalid-email':
        err.message = 'L\'email est invalide.';
        break;
      case 'auth/wrong-password':
        err.message = 'Le mot de passe est invalide.';
        break;
      case 'auth/too-many-requests':
        err.message = 'Trop de tentatives échouées. Veuillez réessayer plus tard.';
        break;
      case 'auth/email-already-in-use':
        err.message = 'L\'email est déjà utilisé par un autre membre.';
        break;
      case 'auth/missing-android-pkg-name':
        err.message = 'Un nom de paquet Android doit être fourni.';
        break;
      case 'auth/missing-continue-uri':
        err.message = 'Url de continuation manquante.';
        break;
      case 'auth/missing-ios-bundle-id':
        err.message = 'Un bundle ID pour iOS doit être fourni.';
        break;
      case 'auth/invalid-continue-uri':
        err.message = 'Url de continuation invalide.';
        break;
      case 'auth/unauthorized-continue-uri':
        err.message = 'Le domaine de l\'url de continuation n\'est pas autorisé.';
        break;
      default:
        err.message = 'Erreur d\'authentification. Veuillez réessayer.';
    }
  
    return Promise.reject(err);
  }
  
  async refreshCurrentUser(): Promise<void> {
    if (this.userAuth && this.userAuth.uid) {
      this.getUserFromFirebaseAuthId(this.userAuth.uid)
        .then((user: User) => {
          this.setCurrentUser(user);
          this.setIsLogged(true);
        })
        .catch(err => {
          //console.log(err);
          this.setCurrentUser(null);
          this.setIsLogged(false);
        });
    } else {
      this.setCurrentUser(null);
      this.setIsLogged(false);
    }
  }

  setUserAuth(userAuth: FirebaseUser): void {
    this.userAuthBehavior.next(userAuth);
  }

  setCurrentUser(currentUser: User): void {
    this.currentUserBehavior.next(currentUser);
  }

  setIsLogged(isLogged: boolean): void {
    this.isLoggedBehavior.next(isLogged);
  }

  setDarkThemeEnabled(darkThemeEnabled: boolean): void {
    this.darkThemeEnabledBehavior.next(darkThemeEnabled);
  }

  async doesUserExistsWithEmail(email: string): Promise<{
    exist: boolean;
    hasAuthConfigured: boolean;
  }> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.doesUserExistsWithEmail,
        {
          email
        }
      );

      return result.doesUserExistsWithEmail as {
        exist: boolean;
        hasAuthConfigured: boolean;
      };
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async add(data: User): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addUser) {
        return result.addUser as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async resendVerificationEmail(email: string): Promise<User> {
    try {
      // Utilisation de la notation shorthand pour { email: email }
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.resendVerificationEmail, { email });

      if (result.resendVerificationEmail) {
        // Créez et présentez une alerte pour confirmer l'envoi
        const alert = await this.alertController.create({
          header: 'C\'est parti 👍',
          message: 'Un lien de validation a été envoyé par email, vérifie ta boîte de réception et tes spams !',
          buttons: ['OK']
        });

        await alert.present();

        return result.resendVerificationEmail as User;
      }
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  async updateEmailVerified(idUser: string): Promise<void> {
    try {
      await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateVerifiedEmailOfUser,
        { id: idUser }
      );
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addNewFcmTokenToCurrentUser(token: string): Promise<User> {
    if (this.currentUser) {
      return this.addNewFcmTokenToUser(this.currentUser.id, token);
    }

    return null;
  }

  async addNewFcmTokenToUser(userId: string, token: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.addFcmTokenToUser,
        {
          userId,
          token
        }
      );

      if (result.addFcmTokenToUser) {
        await this.refreshCurrentUser();

        return result.addFcmTokenToUser as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getConnectedAccountInfo(accountId: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getConnectedAccountInfo,
        {
          accountId
        }
      );

      if (result.connectedAccountInfo) {
        return result.connectedAccountInfo;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getConnectedAccountPayments(accountId: string, lastItem: string = null): Promise<any> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getConnectedAccountPayments,
        {
          accountId,
          lastItem
        }
      );

      if (result.connectedAccountPayments) {
        return result.connectedAccountPayments;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getWithUsername(username: string, userToIgnoreId: string = null): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getItemWithUsername,
        {
          username,
          userToIgnoreId
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getUserByEmail(email: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getItemWithEmail,
        {
          email
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: User): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateBatteryChargingValues(
    userId: string,
    batteryChargeMinimum: number,
    batteryChargeMaximum: number
  ): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateBatteryChargingValues,
        {
          id: userId,
          batteryChargeMinimum,
          batteryChargeMaximum
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateEmspsValues(
    userId: string,
    selectedEmspsId: string[]
  ): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateEmspsValues,
        {
          id: userId,
          selectedEmspsId
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateFirebaseAuthId(email: string, firebaseAuthId: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateFirebaseAuthId,
        {
          email,
          firebaseAuthId
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateCurrentUser(data: User, password: string): Promise<User> {
    try {
      const auth = getAuth(); // Obtenez l'objet d'authentification Firebase
      const currentUser = auth.currentUser; // Récupérez l'utilisateur actuel
  
      if (data.email && currentUser) {
        const credential = EmailAuthProvider.credential(
          currentUser.email, // Email actuel de l'utilisateur
          password // Mot de passe de l'utilisateur pour la réauthentification
        );
  
        // Réauthentification de l'utilisateur avec les informations d'identification
        await reauthenticateWithCredential(currentUser, credential);
  
        // Mise à jour de l'email de l'utilisateur dans Firebase
        await updateEmail(currentUser, data.email);
  
        // Copie de l'utilisateur actuel avant la mise à jour des données supplémentaires
        const currentUserObj = Object.assign({}, this.currentUser);
  
        // Mise à jour des informations de l'utilisateur dans votre système (via GraphQL ou autre)
        const updatedUser: User = await this.update(Object.assign(currentUserObj, data));
  
        // Actualise les informations de l'utilisateur courant
        await this.refreshCurrentUser();
  
        return updatedUser;
      } else {
        // Si l'email n'a pas changé, on met à jour directement l'utilisateur dans votre système
        return this.update(Object.assign(this.currentUser, data));
      }
    } catch (err) {
      // Gestion des erreurs de Firebase liées à la mise à jour de l'utilisateur
      console.error(err);
      return await this.handleAuthError(err);
    }
  }
  
  async updateField(id: string, field: string, value: any): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.updateField, {
        id,
        field,
        value: JSON.stringify(value)
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateStripeUserId(id: string, authorizationCode: any): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateStripeUserId,
        {
          id,
          authorizationCode
        }
      );

      if (result[this.collectionNameSingular]) {
        this.refreshCurrentUser();

        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async createStripeLoginUrl(id: string): Promise<string> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.createStripeLoginLink,
        {
          id
        }
      );

      return result.createStripeLoginLink;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async deleteCurrentUser(password: string): Promise<void> {
    try {
      const auth = getAuth(); // Obtenez l'objet d'authentification Firebase
      const currentUser = auth.currentUser; // Récupérez l'utilisateur actuel
  
      if (!currentUser || !currentUser.email) {
        throw new Error('Utilisateur non trouvé.');
      }
  
      // Créez un objet `credential` pour réauthentifier l'utilisateur avec son email et mot de passe actuel
      const credential = EmailAuthProvider.credential(
        currentUser.email, // Email actuel de l'utilisateur
        password // Mot de passe de l'utilisateur pour la réauthentification
      );
  
      // Réauthentification de l'utilisateur avec les informations d'identification
      await reauthenticateWithCredential(currentUser, credential);
  
      // Supprimer l'utilisateur dans votre système
      await this.delete(this.currentUser.id);
  
      // Supprimer l'utilisateur dans Firebase
      await currentUser.delete();
    } catch (err) {
      console.error(err);
  
      // Utilisation de handleAuthError pour gérer les erreurs d'authentification
      return this.handleAuthError(err);
    }
  }
    
  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<User[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: User[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as User);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async login(email: string, password: string): Promise<User> {
    try {
      // Utilisation de AngularFireAuth pour se connecter avec l'email et le mot de passe
      const userCredential = await this.afAuth.signInWithEmailAndPassword(email, password);
  
      if (userCredential) {
        // Requête GraphQL pour récupérer les informations de l'utilisateur via son FirebaseAuthId
        const result: any = await this.graphqlService.apolloWatchQuery(
          this.grahqlQueries.loginWithEmailAndFirebaseAuthId,
          {
            email,
            firebaseAuthId: userCredential.user.uid  // Accès à l'UID de l'utilisateur Firebase
          }
        );
  
        if (result.loginWithEmailAndFirebaseAuthId) {
          return result.loginWithEmailAndFirebaseAuthId as User;
        }
      }
    } catch (err) {
      console.error(err);
  
      // Gérer les erreurs d'authentification avec ton propre gestionnaire d'erreurs
      return this.handleAuthError(err);
    }
  }

  async loginWithApple(): Promise<{ user: User; userCredential: UserCredential }> {
    try {
      // Initialisation du provider Apple avec Firebase 9 (module)
      const provider = new OAuthProvider('apple.com');
      provider.setCustomParameters({
        locale: 'fr', // Localiser l'authentification en français
      });
  
      // Utilisation de AngularFireAuth pour la connexion avec Apple
      const userCredential = await this.afAuth.signInWithPopup(provider) as unknown as UserCredential;
  
      // Vérifie que l'objet userCredential contient les informations attendues
      console.log(userCredential);
  
      if (userCredential && userCredential.user) {
        // Utilisation de l'email et firebaseAuthId dans la requête GraphQL
        const result: any = await this.graphqlService.apolloWatchQuery(
          this.grahqlQueries.loginWithEmailAndFirebaseAuthId,
          {
            email: userCredential.user.email,
            firebaseAuthId: userCredential.user.uid,
          }
        );
  
        return {
          user: result.loginWithEmailAndFirebaseAuthId
            ? (result.loginWithEmailAndFirebaseAuthId as User)
            : null,
          userCredential,
        };
      }
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }
  
  async loginWithGoogle(): Promise<{
    user: User;
    userCredential: UserCredential;
  }> {
    try {
      const userCredential: UserCredential = await this.signInWithGoogle();

      if (userCredential) {
        const result: any = await this.graphqlService.apolloWatchQuery(
          this.grahqlQueries.loginWithEmailAndFirebaseAuthId,
          {
            email: userCredential.user.email,
            firebaseAuthId: userCredential.user.uid
          }
        );
        //console.log(result);
        return {
          user: result.loginWithEmailAndFirebaseAuthId
            ? (result.loginWithEmailAndFirebaseAuthId as User)
            : null,
          userCredential
        };
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async loginWithFacebook(): Promise<{
    user: User;
    userCredential: UserCredential;
  }> {
    try {
      const userCredential: UserCredential = await this.signInWithFacebook();
      //console.log('userCredential', userCredential);
      if (userCredential) {
        const result: any = await this.graphqlService.apolloWatchQuery(
          this.grahqlQueries.loginWithEmailAndFirebaseAuthId,
          {
            email: userCredential.user.email,
            firebaseAuthId: userCredential.user.uid
          }
        );

        return {
          user: result.loginWithEmailAndFirebaseAuthId
            ? (result.loginWithEmailAndFirebaseAuthId as User)
            : null,
          userCredential
        };
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async signOut(isDelete = false): Promise<void> {
    if (!isDelete) {
      await this.updateField(this.currentUser.id, 'isLogged', false);
    }

    this.userAuthBehavior.next(null);
    this.currentUserBehavior.next(null);
    this.isLoggedBehavior.next(false);
    this.darkThemeEnabledBehavior.next(false);

    await this.signOutFirebaseAuth();
  }

  async register(registerData: User, password: string): Promise<User> {
    try {
      // Crée un nouvel utilisateur avec l'email et le mot de passe
      const userCredential = await this.afAuth.createUserWithEmailAndPassword(
        registerData.email,
        password
      ) as unknown as UserCredential;
      
      // Si l'utilisateur a bien été créé
      if (userCredential && userCredential.user) {
        // Associe l'ID Firebase de l'utilisateur au modèle utilisateur
        registerData.firebaseAuthIds = [userCredential.user.uid];

        // Ajout de l'utilisateur dans le système via GraphQL
        const user: User = await this.add(registerData);

        // Actualise les informations de l'utilisateur courant
        await this.refreshCurrentUser();

        // Retourne l'utilisateur ajouté
        return user;
      } else {
        throw new Error('UserCredential does not contain a valid user.');
      }
    } catch (err) {
      // Gestion des erreurs d'authentification
      await this.handleAuthError(err);
    }
  }

  registerWithUserCredential(
    registerData: User,
    userCredential: UserCredential
  ): Promise<User> {
    try {
      return new Promise(async (resolve, reject) => {
        registerData.firebaseAuthIds = [userCredential.user.uid];

        const user: User = await this.add(registerData);

        this.refreshCurrentUser();

        resolve(user);
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  setPassword(email: string, password: string): Promise<User> {
    try {
      return new Promise((resolve, reject) => {
        this.afAuth.createUserWithEmailAndPassword(email, password)
          .then(async (userCredential) => {
            const firebaseAuthId: string = userCredential.user?.uid || '';
  
            // Appel à votre méthode pour mettre à jour l'authId Firebase
            const user: User = await this.updateFirebaseAuthId(email, firebaseAuthId);
  
            // Rafraîchir l'utilisateur courant après l'enregistrement
            await this.refreshCurrentUser();
  
            resolve(user);
          })
          .catch(async (err) => {
            if (err.code === 'auth/email-already-in-use') {
              try {
                // Si l'email est déjà utilisé, essayer de se connecter
                const existingUserCredential = await this.afAuth.signInWithEmailAndPassword(email, password);
                const firebaseAuthId: string = existingUserCredential.user?.uid || '';
  
                // Mettre à jour l'ID FirebaseAuth de l'utilisateur
                const user: User = await this.updateFirebaseAuthId(email, firebaseAuthId);
  
                // Rafraîchir l'utilisateur courant
                await this.refreshCurrentUser();
  
                resolve(user);
              } catch (signInError) {
                this.handleAuthError(signInError).then(reject);
              }
            } else {
              this.handleAuthError(err).then(reject);
            }
          });
      });
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }  
  
  async resetPassword(email: string): Promise<void> {
    try {
      // Utilisation correcte de AngularFireAuth pour envoyer l'email de réinitialisation
      const userAuth = await this.afAuth.currentUser;

      // Envoie de l'email de réinitialisation de mot de passe
      await this.afAuth.sendPasswordResetEmail(email);
    } catch (err) {
      console.error(err);
      return this.handleAuthError(err);
    }
  }

  async getUserFromFirebaseAuthId(firebaseAuthId: string): Promise<User> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getItemWithFirebaseAuthId,
        {
          firebaseAuthId
        }
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as User;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async updateCurrentUserPassword(originalPassword: string, newPassword: string): Promise<void> {
    try {
      const auth = getAuth(); // Obtenez l'objet d'authentification Firebase
      const currentUser = auth.currentUser; // Récupérez l'utilisateur actuel
  
      if (!currentUser || !currentUser.email) {
        throw new Error('Utilisateur actuel introuvable.');
      }
  
      // Création de l'objet Credential avec l'email et l'ancien mot de passe
      const credential = EmailAuthProvider.credential(currentUser.email, originalPassword);
  
      // Réauthentification de l'utilisateur avec ses anciennes informations
      await reauthenticateWithCredential(currentUser, credential);
  
      // Mise à jour du mot de passe avec le nouveau mot de passe
      await updatePassword(currentUser, newPassword);
    } catch (err) {
      console.error(err);
      return this.handleAuthError(err);
    }
  }
  
  async getCurrentUserWordpressToken(password: string = null): Promise<string> {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
  
      if (password && currentUser && currentUser.email) {
        const credential = EmailAuthProvider.credential(
          currentUser.email,
          password
        );
  
        await reauthenticateWithCredential(currentUser, credential);
      }
  
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.getWordpressToken,
        {
          id: this.currentUser.id,
          password
        }
      );
  
      return result.getWordpressToken as string;
    } catch (err) {
      console.error(err);
  
      // Utilisation de handleAuthError pour gérer les erreurs d'authentification
      return this.handleAuthError(err);
    }
  }

  async search(data: any): Promise<User[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: User[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as User);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  private async signOutFirebaseAuth(): Promise<void> {
    try {
      await this.afAuth.signOut(); // Utilisation de AngularFireAuth pour la déconnexion
    } catch (err) {
      console.error('Error during sign out:', err);
      return this.handleAuthError(err); // Si tu utilises un handler d'erreur
    }
  }
  
  private resetPasswordFirebaseAuth(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth
        .sendPasswordResetEmail(email) // Utilisation de AngularFireAuth pour l'envoi du mail de réinitialisation
        .then(() => {
          resolve();
        })
        .catch(err => {
          // Utilisation du handler d'erreurs personnalisé
          return this.handleAuthError(err);
        });
    });
  }
  
private signInWithGoogle(): Promise<UserCredential> {
  return new Promise((resolve, reject) => {
    const auth = getAuth(); // Récupération de l'instance d'authentification Firebase

    if (this.platform.is('capacitor')) {
      // Code spécifique pour Capacitor
    } else {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        locale: 'fr', // Localisation de l'écran de connexion en français
      });

      // Utilisation de l'API modulaire Firebase v9 pour la connexion avec Google
      signInWithPopup(auth, provider)
        .then((userCredential: UserCredential) => {
          resolve(userCredential);
        })
        .catch(err => {
          // Gestion des erreurs avec la fonction handleAuthError
          this.handleAuthError(err).catch(reject);
        });
    }
  });
}  

private signInWithApple(): Promise<UserCredential> {
  return new Promise((resolve, reject) => {
    const auth = getAuth();

    if (this.platform.is('capacitor') && this.platform.is('ios')) {
      // Code spécifique pour Capacitor
    } else {
      const provider = new OAuthProvider('apple.com');
      provider.setCustomParameters({
        locale: 'fr' // Localiser l'authentification en français
      });

      signInWithPopup(auth, provider) // Utilisation correcte de Firebase v9 pour l'authentification avec Apple
        .then((userCredential: UserCredential) => {
          resolve(userCredential); // Résolution correcte du type UserCredential
        })
        .catch(err => {
          this.handleAuthError(err).catch(reject); // Utilisation du gestionnaire d'erreurs centralisé
        });
    }
  });
}
    
private signInWithFacebook(): Promise<UserCredential> {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth();

    if (this.platform.is('capacitor')) {
      // Code spécifique à Capacitor
    } else {
      const provider = new FacebookAuthProvider();
      provider.addScope('email');
      provider.setCustomParameters({
        locale: 'fr', // Localisation en français
      });

      signInWithPopup(auth, provider) // Utilisation correcte de Firebase v9
        .then((userCredential: UserCredential) => {
          resolve(userCredential);
        })
        .catch((err) => {
          this.handleAuthError(err).catch(reject); // Utilisation du gestionnaire d'erreurs centralisé
        });
    }
  });
  }

  async linkWithCredential(user: any, pendingCredential: any): Promise<UserCredential> {
    return user.linkWithCredential(pendingCredential);
  }  
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
  vertical-align: top;
}
td.vertical-align-middle {
  vertical-align: middle;
}
td .form-group {
  margin-bottom: 0;
}

ion-footer .form-group {
  text-align: center;
}
ion-footer .form-group .checkbox-container {
  display: inline-block;
  margin: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInF1b3RhdGlvbi1lZGl0LnBhZ2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLG1CQUFBO0FBQ0Y7QUFDRTtFQUNFLHNCQUFBO0FBQ0o7QUFFRTtFQUNFLGdCQUFBO0FBQUo7O0FBS0U7RUFDRSxrQkFBQTtBQUZKO0FBR0k7RUFDRSxxQkFBQTtFQUNBLFlBQUE7QUFETiIsImZpbGUiOiJxdW90YXRpb24tZWRpdC5wYWdlLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJ0ZCB7XHJcbiAgdmVydGljYWwtYWxpZ246IHRvcDtcclxuXHJcbiAgJi52ZXJ0aWNhbC1hbGlnbi1taWRkbGUge1xyXG4gICAgdmVydGljYWwtYWxpZ246IG1pZGRsZTtcclxuICB9XHJcblxyXG4gIC5mb3JtLWdyb3VwIHtcclxuICAgIG1hcmdpbi1ib3R0b206IDA7XHJcbiAgfVxyXG59XHJcblxyXG5pb24tZm9vdGVyIHtcclxuICAuZm9ybS1ncm91cCB7XHJcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XHJcbiAgICAuY2hlY2tib3gtY29udGFpbmVyIHtcclxuICAgICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xyXG4gICAgICBtYXJnaW46IGF1dG87XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/app/profile/quotations/quotation-edit/quotation-edit.page.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,sBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;;AAKE;EACE,kBAAA;AAFJ;AAGI;EACE,qBAAA;EACA,YAAA;AADN;AACA,o0BAAo0B","sourcesContent":["td {\r\n  vertical-align: top;\r\n\r\n  &.vertical-align-middle {\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .form-group {\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n\r\nion-footer {\r\n  .form-group {\r\n    text-align: center;\r\n    .checkbox-container {\r\n      display: inline-block;\r\n      margin: auto;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

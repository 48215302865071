import { Injectable } from '@angular/core';
import { Stripe } from '@capacitor-community/stripe';

@Injectable({
  providedIn: 'root'
})
export class StripeServiceService {
  processSheet: 'willReady' | 'Ready' = 'willReady';

  constructor() {}

  init(): void {
    Stripe.initialize({
      // publishableKey: 'pk_test_51KiL9dBIdQ6QYTIF9oYEWENQdsrUtasUNHvqKPAv4eAyuiC6EbD0ShRli4XaCfx6W14xAT9A1KV2LwA9oNIliwfP00HA97MQUw'

      /**
       * Danger: This is production environment using production key.
       * For testing ApplePay and GooglePay, but If it fails, payment will occur.
       */
      publishableKey: 'pk_live_51KiL9dBIdQ6QYTIFYuF1z7sMO5YvIgOejKtDEk0RymD6RxCFb4OeIOdl2Yubbo989M73aB0DzfCftxsF6hp168Do00e3UDpazy'
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { ChargingStationActivity } from 'src/app/models/charging-station-activity.interface';
import { ChargingStation } from 'src/app/models/charging-station.interface';
import { User } from 'src/app/models/user.interface';
import { ChargingStationActivityService } from 'src/app/services/charging-station-activity.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-leave-comment',
  templateUrl: './leave-comment.component.html',
  styleUrls: ['./leave-comment.component.scss']
})
export class LeaveCommentComponent implements OnInit {
  @Input() chargingStation: ChargingStation;

  form: FormGroup;
  currentUser: User;

  sending = false;

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private chargingStationActivityService: ChargingStationActivityService,
    private alertController: AlertController
  ) {
    this.userService.currentUserObservable.subscribe((currentUser: User) => {
      this.currentUser = currentUser;
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      message: ['', [Validators.required]]
    });
  }

  async submitForm(): Promise<void> {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.sending = true;
      this.form.disable();

      try {
        const result: ChargingStationActivity =
          await this.chargingStationActivityService.addChargingStationActivityFromCurrentUser(
            this.chargingStation.id,
            'user-comment',
            'a laissé un commentaire',
            {
              message: this.form.value.message
            }
          );

        this.form.reset();

        this.form.enable();

        const alertEl = await this.alertController.create({
          cssClass: 'custom-alert',
          header: 'Succès',
          message: 'Commentaire sauvegardé avec succès.',
          buttons: [
            {
              text: 'Ok',
              cssClass: 'success',
              handler: () => {
                this.close();
              }
            }
          ]
        });

        await alertEl.present();
      } catch (err) {
        //console.log(err);

        this.form.enable();

        const alertEl = await this.alertController.create({
          cssClass: 'custom-alert',
          header: 'Erreur',
          message: err.message,
          buttons: [
            {
              text: 'Ok',
              cssClass: 'danger',
              handler: () => {
                this.form.enable();
              }
            }
          ]
        });

        await alertEl.present();
      } finally {
        this.sending = false;
        this.form.enable();
      }
    }
  }

  close() {
    this.modalController.dismiss();
  }
}

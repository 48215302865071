import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { ActionSheetController, LoadingController, Platform } from '@ionic/angular';
import { differenceInHours, parseISO } from 'date-fns';
import { finalize } from 'rxjs/operators';
import { Badge } from 'src/app/models/badge.interface';
import { User } from 'src/app/models/user.interface';
import { UploadDocumentService } from 'src/app/services/upload-document.service';
import { UserService } from 'src/app/services/user.service';

import { Camera, CameraResultType } from '@capacitor/camera';
import { GamificationType } from 'src/app/models/gamification.interface';
import { UserGamificationService } from 'src/app/services/user-gamification.service';

import {
  faPlus
} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-user-view-header-snippet',
  templateUrl: './user-view-header-snippet.component.html',
  styleUrls: ['./user-view-header-snippet.component.scss']
})
export class UserViewHeaderSnippetComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() editProfilPicture = false;

  @ViewChild('inputFile') inputFile: ElementRef;

  // Data passed in by componentProps
  @Input() field: string;
  @Input() imagePreview: string;

  actionSheet: any;
  uploadingLoader: any;

  currentUser: User;

  userBadges: {
    badge: Badge;
    count: number;
  }[] = [];

  faPlus = faPlus;

  constructor(
    private actionSheetController: ActionSheetController,
    private loadingController: LoadingController,
    private uploadDocumentService: UploadDocumentService,
    private userService: UserService,
    private platform: Platform,
    private userGamificationService: UserGamificationService
  ) {
    this.userService.currentUserObservable.subscribe((currentUser: User) => {
      this.currentUser = currentUser;
    });
  }

  ngOnInit() {
    this.processUserBadges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && changes.user.previousValue !== changes.user.currentValue) {
      this.processUserBadges();
    }
  }

  isUserOnline(lastLoginDate: string, isLogged: boolean): boolean {
    if (lastLoginDate && isLogged) {
      return differenceInHours(new Date(), parseISO(lastLoginDate)) <= 1; // Online if connected up to 2 hours ago max
    }

    return false;
  }

  processUserBadges(): void {
    if (this.user) {
      this.userBadges = [];

      const userBadgesById: any = {};

      if (this.user.userBadges) {
        for (const userBadge of this.user.userBadges) {
          if (typeof userBadgesById[userBadge.badgeId] === 'undefined') {
            userBadgesById[userBadge.badgeId] = {
              badge: userBadge.badge,
              count: 0
            };
          }

          userBadgesById[userBadge.badgeId].count++;
        }
      }

      // eslint-disable-next-line guard-for-in
      for (const badgeId in userBadgesById) {
        this.userBadges.push(userBadgesById[badgeId]);
      }
    }
  }

  async openMenuDropdownPicture($event: Event): Promise<void> {
    if (this.editProfilPicture) {
      if (this.actionSheet) {
        await this.actionSheet.dismiss();
      }

      if (this.user?.profilePictureUrl) {
        this.actionSheet = await this.actionSheetController.create({
          buttons: [
            {
              text: 'Modifier',
              handler: this.editProfilePicture.bind(this)
            },
            {
              text: 'Supprimer',
              handler: this.deleteProfilePicture.bind(this)
            },
            {
              text: 'Annuler',
              role: 'cancel'
            }
          ]
        });

        this.actionSheet.present();
      } else {
        this.selectImgSource();
      }
    }
  }

  editProfilePicture(): void {
    this.presentActionSheet();
  }

  async presentActionSheet(): Promise<void> {
    if (this.actionSheet) {
      await this.actionSheet.dismiss();

      this.selectImgSource();
    } else {
      this.selectImgSource();
    }
  }

  async selectImgSource(): Promise<void> {
    if (this.platform.is('capacitor')) {
      const image = await Camera.getPhoto({
        quality: 100,
        resultType: CameraResultType.Base64,
        promptLabelHeader: 'Photo',
        promptLabelCancel: 'Annuler',
        promptLabelPhoto: 'De la galerie',
        promptLabelPicture: 'Prendre une photo',
        webUseInput: true
      });

      this.imagePreview = 'data:image/jpeg;base64,' + image.base64String;

      this.upload();
    } else {
      this.inputFile.nativeElement.click();
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imagePreview = e.target.result;

        this.upload();
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  async deleteProfilePicture(): Promise<void> {
    await this.presentUploadingLoader('Suppression de l\'image ...');

    await this.uploadDocumentService.deleteFile(this.user.profilePictureUrl);

    await this.updateRemoveProfilPicture(null);

    await this.hideUploadingLoader();
  }

  async presentUploadingLoader(message: string): Promise<void> {
    this.uploadingLoader = await this.loadingController.create({
      message
    });
    await this.uploadingLoader.present();
  }

  hideUploadingLoader(): void {
    if (this.uploadingLoader) {
      this.uploadingLoader.dismiss();
    }
  }

  upload(): void {
    if (this.imagePreview) {
      this.presentUploadingLoader("Envoi de l'image au serveur ...");

      const result: {
        ref: AngularFireStorageReference;
        task: AngularFireUploadTask;
      } = this.uploadDocumentService.upload('profile', null, '.jpg', this.imagePreview, 'data_url');

      result.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result.ref.getDownloadURL().subscribe(async (downloadUrl: string) => {
              await this.updateRemoveProfilPicture(downloadUrl);

              this.hideUploadingLoader();
            });
          })
        )
        .subscribe();
    }
  }
  
  async updateRemoveProfilPicture(url: string | null): Promise<void> {
    await this.userService.update({
      id: this.user.id,
      profilePictureUrl: url
    } as User);

    await this.userService.refreshCurrentUser();

    await this.userGamificationService.addUserGamificationForCurrentUserWithGamificationCode(
      url ? GamificationType.addProfilPicture : GamificationType.deleteProfilPicture
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Ad, AdContainer } from 'src/app/models/ad.interface';
import { AdService } from 'src/app/services/ad.service';

@Component({
  selector: 'app-ad-snippet',
  templateUrl: './ad-snippet.component.html',
  styleUrls: ['./ad-snippet.component.scss']
})
export class AdSnippetComponent implements OnInit {
  @Input() container: AdContainer;
  @Input() index = 0;

  ad: Ad;

  html: SafeHtml;

  constructor(private adService: AdService, private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.loadAd();
  }

  async loadAd(): Promise<void> {
    const ads: Ad[] = await this.adService.getAllInContainer(this.container);

    if (typeof ads[this.index] !== 'undefined') {
      this.ad = ads[this.index];

      if (this.ad?.content) {
        this.html = this.domSanitizer.bypassSecurityTrustHtml(this.ad.content);
      }
    }
  }
}

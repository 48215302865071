import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MenuController, NavController, LoadingController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { User } from '../models/user.interface';

import { UserService } from '../services/user.service';
import { ChatService } from '../services/chat.service';
import { NotificationService } from '../services/notification.service';
import { SideMenuService } from '../services/side-menu.service';

import * as fontSoligAwesome from '@fortawesome/free-solid-svg-icons';

import {
  IconDefinition as IconDefinitionBrand,
  faXTwitter,
  faFacebookF,
  faInstagram,
  faLinkedin,
  faYoutube,
  faDiscord
} from '@fortawesome/free-brands-svg-icons';
import { UrlOpenerService } from '../services/url-opener.service';
import { Page, PageContainer, PageType, PageVisibility } from '../models/page.interface';
import { PageService } from '../services/page.service';
import { AdContainer } from '../models/ad.interface';
import { AppSetting } from '../models/app-setting.interface';
import { SpotSuggestionStationPage } from '../app/spot/spot-suggestion-station/spot-suggestion-station.page';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { FiltersService } from 'src/app/services/spot-filters.service';
import { SpotSearchService } from 'src/app/services/spot-search.service';

interface MenuItem {
  title: string;
  url: string;
  icon: fontSoligAwesome.IconDefinition | IconDefinitionBrand;
  goToFunction?: string;
  shaded?: boolean;
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.page.html',
  styleUrls: ['./side-menu.page.scss']
})
export class SideMenuPage implements OnInit {
  fontSoligAwesome = fontSoligAwesome;
  currentUser: User;
  unreadChats = 0;
  unreadNotifications = 0;

  faRobot = fontSoligAwesome.faRobot;
  faBell = fontSoligAwesome.faBell;
  faUser = fontSoligAwesome.faUser;
  faUsers = fontSoligAwesome.faUsers;
  faSignInAlt = fontSoligAwesome.faSignInAlt;
  faLock = fontSoligAwesome.faLock;
  faLockOpen = fontSoligAwesome.faLockOpen;
  faComment = fontSoligAwesome.faComment;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AdContainer = AdContainer;

  loading = true;

  socialNetworks: {
    icon: IconDefinitionBrand;
    url: string;
  }[] = [
    {
      icon: faXTwitter,
      url: 'https://twitter.com/spotNcharge'
    },
    {
      icon: faFacebookF,
      url: 'https://www.facebook.com/spotncharge/'
    },
    {
      icon: faInstagram,
      url: 'https://www.instagram.com/spotNcharge/'
    },
    {
      icon: faLinkedin,
      url: 'https://www.linkedin.com/company/spotncharge'
    },
    {
      icon: faYoutube,
      url: 'https://www.youtube.com/@spotncharge'
    }
  ];

  menuItems: Array<MenuItem> = [];
  goToFunction: string;
  shaded: boolean;

  constructor(
    private userService: UserService,
    private zone: NgZone,
    private chatService: ChatService,
    private notificationService: NotificationService,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private loadingController: LoadingController,
    private sideMenuService: SideMenuService,
    private urlOpenerService: UrlOpenerService,
    private pageService: PageService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalController: ModalController,
    private appSettingService: AppSettingService,
    private filtersService: FiltersService,
    private spotSearchService: SpotSearchService,
    private router: Router
  ) {
    this.userService.currentUserObservable.subscribe((currentUser: User) => {
      this.currentUser = currentUser;

      this.setUpMenuLinks();

      this.zone.run(async () => {
        await this.chatService.getUnreadChatForCurrentUser();
        await this.notificationService.getUnreadNotificationForCurrentUser();
      });
    });

    this.notificationService.countCurrentUserNotificationsUnreadObservable.subscribe(
      (countCurrentUserNotificationsUnread: number) => {
        this.zone.run(() => {
          this.unreadNotifications = countCurrentUserNotificationsUnread;
        });
      }
    );

    this.chatService.countCurrentUserChatsUnreadObservable.subscribe((unreadChats: number) => {
      this.zone.run(() => {
        this.unreadChats = unreadChats;
      });
    });
  }

  ngOnInit() {
    this.setUpMenuLinks();
  }

  async showSuggestionStationModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: SpotSuggestionStationPage
    });
    return await modal.present();
  }

  async setUpMenuLinks(): Promise<void> {
    this.menuItems = [];

    this.addToMenu({
      title: 'Calculer les temps',
      url: '/charge/time',
      icon: fontSoligAwesome.faChartBar,
      shaded: true
    });
    this.addToMenu({
      title: 'Comparer les tarifs',
      url: '/charge/cost',
      icon: fontSoligAwesome.faChartSimple,
      shaded: true
    });
    this.addToMenu({
      title: 'Optimiser les itinéraires',
      url: '/charge/itinerary',
      icon: fontSoligAwesome.faRoute,
      shaded: true
    });
    this.addToMenu({
      title: 'Quoi de neuf ?',
      url: '/news',
      icon: fontSoligAwesome.faRss
    });
    this.addToMenu({
      title: 'Communauté',
      url: '',
      icon: faDiscord,
      goToFunction: 'openDiscord'
    });
    this.addToMenu({
      title: this.currentUser ? 'Se déconnecter' : 'Se connecter / S\'inscrire',
      url: this.currentUser ? '/sign-out' : '/menu',
      icon: this.currentUser ? fontSoligAwesome.faLockOpen : fontSoligAwesome.faLock
    });

    await this.loadPages();

    this.loading = false;
  }

  addToMenu(newMenuItem: MenuItem): void {
    this.zone.run(() => {
      this.menuItems.push(newMenuItem);
    });
  }

  openMenuItemUrl(url: string, goToFunction: string = null): void {
    if (url === '/sign-out') {
      this.menuCtrl.close();

      this.signOut();
    } else if (['/menu'].indexOf(url) !== -1 && !this.currentUser) {
      this.sideMenuService.navigateForward('welcome');
    } else {
      this.menuCtrl.close();

      this.navCtrl.navigateRoot(url);

      if (goToFunction) {
        if (goToFunction === 'showSuggestionStationModal') {
          this.showSuggestionStationModal();
        } else if (goToFunction === 'openDiscord') {
          this.openDiscord();
        }
      }
    }
  }

  async openDiscord() {
    const appSettingCode = 'discord_url';
    const DiscordUrl: AppSetting = await this.appSettingService.getFromCode(appSettingCode);
  
    if (DiscordUrl && DiscordUrl.value) {
      this.urlOpenerService.openUrl(DiscordUrl.value);
    }
  }
  
  openUrl(url: string, root?: boolean): void {
    this.menuCtrl.close();

    if (root) {
      this.navCtrl.navigateRoot(url);
    } else {
      this.navCtrl.navigateForward(url);
    }
  }

  async signOut(): Promise<void> {
    const loading = await this.loadingController.create({
      message: 'Déconnexion...'
    });
    await loading.present();
  
    this.filtersService.initializeFilters();
    this.spotSearchService.initializeFilters();

    await this.userService.signOut();
  
    await this.navCtrl.navigateRoot('/spot');
  
    await loading.dismiss();
  }
  
  openWebsiteUrl(url: string): void {
    this.menuCtrl.close();

    this.urlOpenerService.openUrl(url);
  }

  async loadPages(): Promise<void> {
    const pages: Page[] = await this.pageService.getAllInContainer(PageContainer.SideMenu);

    for (const page of pages) {
      if (this.checkPageIsVisibleForUser(page)) {
        let url = '';

        switch (page.type) {
          case PageType.WordpressPage:
            url = '/page/' + page.slug + '/' + page.id;
            break;
          case PageType.GravityForm:
            url = '/form/' + page.slug + '/' + page.id;
            break;
        }

        let pageIcon: fontSoligAwesome.IconDefinition;

        for (const icon in this.fontSoligAwesome) {
          if (this.fontSoligAwesome[icon].iconName === page.icon) {
            pageIcon = this.fontSoligAwesome[icon];
            break;
          }
        }

        this.addToMenu({
          title: page.title,
          url,
          icon: pageIcon ? pageIcon : fontSoligAwesome.faFile
        });
      }
    }
  }

  checkPageIsVisibleForUser(page: Page): boolean {
    switch (page.pageVisibility) {
      case PageVisibility.none:
        return false;
      case PageVisibility.loggedInUsers:
        return !!this.currentUser;
      case PageVisibility.notLoggedInUsers:
        return !this.currentUser;
      case PageVisibility.loggedInUsersNotPro:
        return !!this.currentUser && !this.currentUser.isPro;
      case PageVisibility.loggedInUsersPro:
        return !!this.currentUser && this.currentUser.isPro;
      case PageVisibility.all:
      default:
        return true;
    }
  }
}

/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router } from '@angular/router';

import { UserService } from '../services/user.service';
import { SideMenuService } from '../services/side-menu.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.page.html',
  styleUrls: ['./set-password.page.scss']
})
export class SetPasswordPage implements OnInit {
  form: FormGroup;
  sending = false;

  showPassword = false;
  passwordInputType = 'password';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private sideMenuService: SideMenuService
  ) {}

  ngOnInit() {
    const emailFilledIn: string = window.localStorage.getItem('emailFilledIn');

    this.form = this.formBuilder.group(
      {
        email: [emailFilledIn ? emailFilledIn : '', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
      },
      { validator: this.checkPasswords }
    );
  }

  async submitForm(): Promise<void> {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      // We save
      this.sending = true;

      this.form.disable();

      try {
        await this.userService.setPassword(this.form.value.email, this.form.value.password);

        this.sending = false;
        this.form.enable();
        this.form.reset();

        this.goToRoot();
      } catch (err) {
        //console.log(err);

        alert(err.message);

        this.sending = false;
        this.form.enable();
      }
    }
  }

  goBack(): void {
    this.sideMenuService.navigateBack();
  }

  goToRoot(): void {
    this.sideMenuService.goToRoot();
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.passwordInputType = this.showPassword ? 'text' : 'password';
  }

  private checkPasswords(group: FormGroup): object {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSamePassword: true };
  }
}

import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, split } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { HttpLink } from 'apollo-angular/http';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { Capacitor } from '@capacitor/core';

let useLocalUrls = false;
if (Capacitor.isNativePlatform()) {
  useLocalUrls = false;
} else if (window.location.href.match('localhost')) {
  useLocalUrls = true;
} else {
  useLocalUrls = false;
}

const uri = useLocalUrls
  ? 'http://localhost:3000/graphql'
  : 'https://app.spotncharge.net:3000/graphql';

const wsUri = useLocalUrls
  ? 'ws://localhost:3000/graphql'
  : 'wss://app.spotncharge.net:3000/graphql';

// const uri = 'https://app.spotncharge.net:3000/graphql';

// const wsUri = 'wss://app.spotncharge.net:3000/graphql';

export const createApollo = (httpLink: HttpLink): ApolloClientOptions<any> => {
  const http = httpLink.create({
    uri
  });

  const ws = new GraphQLWsLink(
    createClient({
      url: wsUri
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    ws,
    http
  );

  return {
    link: splitLink,
    cache: new InMemoryCache()
  };
};

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}

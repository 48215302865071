/* eslint-disable max-len */
import { Injectable } from '@angular/core';

import { GraphqlService } from './graphql.service';
import { User } from '../models/user.interface';
import { UserService } from './user.service';
import { ChargingStationActivity } from '../models/charging-station-activity.interface';
import { GraphQLInputObjectType, GraphQLInt, GraphQLString } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class ChargingStationActivityService {
  collectionNamePlural = 'chargingStationActivities';
  collectionNameSingular = 'chargingStationActivity';

  filtersType = new GraphQLInputObjectType({
    name: 'filterType',
    fields: {
      itemsPerPage: { type: GraphQLInt },
      orderBy: { type: GraphQLString },
      direction: { type: GraphQLString },
      page: { type: GraphQLInt }
    }
  });

  graphqlQueries: {
    getAllItems: string;
    getChargingStationActivities: string;
    getChargingStationMessages: string;
    getItem: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
    addChargingStationActivity: string;
    countUnreadChargingStationActivities: string;
  } = {
    getAllItems: `
		  query {
		    chargingStationActivities {
          id,
          message,
          createdAt,
          type
          data

          user {
            id
            username
            profilePictureUrl
          }
        }
      }
		`,
    getChargingStationActivities: `
		  query getChargingStationActivities(
        $chargingStationId: String!, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String
        ) {
            chargingStationActivities (
              chargingStationId: $chargingStationId,
              page: $page,
              itemsPerPage: $itemsPerPage,
              orderBy: $orderBy,
              direction: $direction,
            ) {,
              id,
              message,
              createdAt,
              type
              data

              user {
                id
                username
                profilePictureUrl
              }
            }
          }
		`,
    getChargingStationMessages: `
		  query getChargingStationMessages(
        $chargingStationId: String!, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String
        ) {
            chargingStationActivities (
              chargingStationId: $chargingStationId,
              page: $page,
              itemsPerPage: $itemsPerPage,
              orderBy: $orderBy,
              direction: $direction,
            ) {,
              id,
              message,
              createdAt,
              type
              data

              user {
                id
                username
                profilePictureUrl
              }
            }
          }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    chargingStationActivity(id: $id) {
          id,
          message,
          createdAt,
          type
          data

          user {
            id
            username
            profilePictureUrl
          }
        }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteChargingStationActivity(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem($id: ID!, $title: String!, $version: String!, $chargingStationActivityId: String!, $data: JSON) {
				updateChargingStationActivity(id: $id, title: $title, version: $version, chargingStationActivityId: $chargingStationActivityId, data: $data) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem($title: String!, $version: String!, $chargingStationActivityId: String!, $type: String!, $data: JSON) {
				addChargingStationActivity(title: $title, version: $version, chargingStationActivityId: $chargingStationActivityId, type: $type, data: $data) {
					id
				}
			}
		`,
    addChargingStationActivity: `
			mutation addChargingStationActivity($userId: String!, $chargingStationId: String!, $message: String!, $type: String!, $data: JSON) {
				addChargingStationActivity(message: $message, chargingStationId: $chargingStationId, userId: $userId, type: $type, data: $data) {
					id
					message,
					userId
					chargingStationId
					createdAt
          type
          data
				}
			}
		`,
    countUnreadChargingStationActivities: `
		  query countUnreadChargingStationActivities($userId: String!) {
		    countUnreadChargingStationActivities(userId: $userId)
		  }
		`
  };

  currentUser: User;

  constructor(private graphqlService: GraphqlService, private userService: UserService) {
    this.userService.currentUserObservable.subscribe(
      (currentUser: User) => (this.currentUser = currentUser)
    );
  }

  async add(data: ChargingStationActivity): Promise<ChargingStationActivity> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.addItem, data);

      if (result.addChargingStationActivity) {
        return result.addChargingStationActivity as ChargingStationActivity;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<ChargingStationActivity> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.graphqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        const chargingStationActivity: ChargingStationActivity = result[
          this.collectionNameSingular
        ] as ChargingStationActivity;

        return chargingStationActivity;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: ChargingStationActivity): Promise<ChargingStationActivity> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as ChargingStationActivity;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(): Promise<ChargingStationActivity[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getAllItems
      );

      const items: ChargingStationActivity[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ChargingStationActivity);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addChargingStationActivityFromCurrentUser(
    chargingStationId: string,
    type: string,
    message: string,
    data: any = null
  ): Promise<ChargingStationActivity> {
    if (this.currentUser) {
      return await this.addChargingStationActivityByUser(
        this.currentUser.id,
        chargingStationId,
        type,
        message,
        data
      );
    } else {
      return null;
    }
  }

  async addChargingStationActivityByUser(
    userId: string,
    chargingStationId: string,
    type: string,
    message: string,
    data: any = null
  ): Promise<ChargingStationActivity> {
    try {
      //console.log(userId, chargingStationId, message);
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.addChargingStationActivity,
        {
          userId,
          chargingStationId,
          type,
          message,
          data
        }
      );

      if (result.addChargingStationActivity) {
        return result.addChargingStationActivity as ChargingStationActivity;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addMessageAndCreateChargingStationActivityFromCurrentUser(
    otherUserId: string,
    message: string
  ): Promise<ChargingStationActivity> {
    if (this.currentUser) {
      return await this.addMessageAndCreateChargingStationActivityByUsers(
        this.currentUser.id,
        otherUserId,
        message
      );
    } else {
      return null;
    }
  }

  async addMessageAndCreateChargingStationActivityByUsers(
    userSenderId: string,
    userReceivingId: string,
    message: string
  ): Promise<ChargingStationActivity> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.addChargingStationActivity,
        {
          userId: userSenderId,
          userReceivingId,
          message
        }
      );

      if (result.addChargingStationActivity) {
        return result.addChargingStationActivity as ChargingStationActivity;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getChargingStationActivities(
    chargingStationId: string,
    filters: any
  ): Promise<ChargingStationActivity[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getChargingStationActivities,
        {
          chargingStationId,
          page: filters.page,
          itemsPerPage: filters.itemsPerPage,
          orderBy: filters.orderBy,
          direction: filters.direction
        }
      );

      const items: ChargingStationActivity[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ChargingStationActivity);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getChargingStationMessages(
    chargingStationId: string,
    filters: any
  ): Promise<ChargingStationActivity[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getChargingStationMessages,
        {
          chargingStationId,
          page: filters.page,
          itemsPerPage: filters.itemsPerPage,
          orderBy: filters.orderBy,
          direction: filters.direction
        }
      );

      const items: ChargingStationActivity[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ChargingStationActivity);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getUnreadChargingStationActivityForCurrentUser(): Promise<number> {
    if (this.currentUser) {
      return await this.getUnreadChargingStationActivityForUser(this.currentUser.id);
    } else {
      return null;
    }
  }

  async getUnreadChargingStationActivityForUser(userId: string): Promise<number> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.countUnreadChargingStationActivities,
        {
          userId
        }
      );

      if (result.countUnreadChargingStationActivities) {
        return parseInt(result.countUnreadChargingStationActivities, 10);
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}

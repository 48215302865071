import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { Ad, AdContainer } from '../models/ad.interface';

import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class AdService {
  collectionNamePlural = 'ads';
  collectionNameSingular = 'ad';

  grahqlQueries: {
    getAllItems: string;
    getAllItemsInContainer: string;
    getItem: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
  } = {
    getAllItems: `
      query getAllItems($query: String, $ad: Int, $itemsPerAd: Int, $orderBy: String, $direction: String, $container: String) {
        ads (
          ad: $ad,
          itemsPerAd: $itemsPerAd,
          orderBy: $orderBy,
          direction: $direction,
          query: $query,
          container: $container
        ) {
          id
          title
          content
          container
        }
		  }
		`,
    getAllItemsInContainer: `
      query getAllItemsInContainer($container: String!) {
        ads (
          container: $container
        ) {
          id
          title
          content
          container
        }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    ad(id: $id) {
				id
				title
                content
                container
            }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteAd(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem(
                $id: ID!, 
                $title: String!, 
                $content: String,
                $container: String
            ) {
				updateAd(
                    id: $id, 
                    title: $title,
                    content: $content,
                    container: $container
                ) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem(
                $title: String!, 
                $content: String,
                $container: String
            ) {
				addAd(
                    title: $title,
                    content: $content,
                    container: $container
                ) {
					id
				}
			}
		`
  };

  constructor(private graphqlService: GraphqlService) {}

  async add(data: Ad): Promise<Ad> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addAd) {
        return result.addAd as Ad;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<Ad> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Ad;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: Ad): Promise<Ad> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as Ad;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<Ad[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: Ad[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Ad);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAllInContainer(container: AdContainer): Promise<Ad[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        {
          container
        }
      );

      const items: Ad[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Ad);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async search(query: string): Promise<Ad[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        { query }
      );

      const items: Ad[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as Ad);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}

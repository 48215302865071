import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  currentUrl$: Observable<string[]>;
  presentMainTab$: Observable<boolean>;

  private currentUrl = new BehaviorSubject<string[]>([]);

  private presentMainTab = new BehaviorSubject<boolean>(true);

  constructor() {
    this.currentUrl$ = this.currentUrl.asObservable();
    this.presentMainTab$ = this.presentMainTab.asObservable();
  }

  updateTabBarFromUrl(url: string) {
    const splittedUrl = this.splitUrl(url);
    this.currentUrl.next(splittedUrl);
  }

  hideMainTabBar() {
    // this.presentMainTab.next(false);
  }

  showMainTabBar() {
    this.presentMainTab.next(true);
  }

  private splitUrl(url: string): string[] {
    return url.split('/').filter(part => part && part !== 'app');
  }
}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { ForbiddenWord } from '../models/forbidden-word.interface';

import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class ForbiddenWordService {
  collectionNamePlural = 'forbiddenWords';
  collectionNameSingular = 'forbiddenWord';

  forbiddenWords: ForbiddenWord[] = [];

  grahqlQueries: {
    getAllItems: string;
    getItem: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
  } = {
    getAllItems: `
      query getAllItems($query: String, $page: Int, $itemsPerPage: Int, $orderBy: String, $direction: String) {
        forbiddenWords (
          page: $page,
          itemsPerPage: $itemsPerPage,
          orderBy: $orderBy,
          direction: $direction,
          query: $query
        ) {
          id
          text
		    }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    forbiddenWord(id: $id) {
				id
				text
		    }
		  }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteForbiddenWord(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem($id: ID!, $text: String!) {
				updateForbiddenWord(id: $id, text: $text) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem($text: String!) {
				addForbiddenWord(text: $text) {
					id
				}
			}
		`
  };

  constructor(private graphqlService: GraphqlService) {}

  async add(data: ForbiddenWord): Promise<ForbiddenWord> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.addItem, data);

      if (result.addForbiddenWord) {
        return result.addForbiddenWord as ForbiddenWord;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<ForbiddenWord> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.grahqlQueries.getItem, {
        id
      });

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as ForbiddenWord;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: ForbiddenWord): Promise<ForbiddenWord> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.grahqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as ForbiddenWord;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.grahqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<ForbiddenWord[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        data
      );

      const items: ForbiddenWord[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ForbiddenWord);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAllOnce(): Promise<ForbiddenWord[]> {
    if (!this.forbiddenWords.length) {
      this.forbiddenWords = await this.getAll({ itemsPerPage: 99999 });
    }

    return this.forbiddenWords;
  }

  async search(query: string): Promise<ForbiddenWord[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.grahqlQueries.getAllItems,
        { query }
      );

      const items: ForbiddenWord[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ForbiddenWord);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async hasForbiddenWords(text: string, strict: boolean = false): Promise<boolean> {

    // Si la liste des mots interdits est vide ou non définie, récupérez-la.
    if (!this.forbiddenWords?.length) {
      await this.getAllOnce();
    }
  
    for (const forbiddenWord of this.forbiddenWords) {
      // Mode non strict
      if (text.split(' ').includes(forbiddenWord.text)) {
        return true;
      }
      
      // Mode strict
      if (strict) {
        const regex = new RegExp('\\b' + forbiddenWord.text + '\\b', 'i');
        if (regex.test(text)) {
          return true;
        }
      }
    }
  
    return false;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { QuotationEditPageRoutingModule } from './quotation-edit-routing.module';

import { QuotationEditPage } from './quotation-edit.page';

import { UserViewHeaderSnippetComponentModule } from 'src/app/components/user-view-header-snippet/user-view-header-snippet.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    QuotationEditPageRoutingModule,
    UserViewHeaderSnippetComponentModule
  ],
  declarations: [QuotationEditPage]
})
export class QuotationEditPageModule {}
